



import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TimeSlider from "../TimeSlider/TimeSlider.vue";
import { MappingService } from "../../../services/mappingService";
import { QueryService } from "../../../services/queryService";
import { ConfigService } from "../../../services/configService";
import Feature from "ol/Feature";
import { bus } from "../../../main";
import { IMetadata } from "../../../interfaces/IMetadata";

@Component({
  components: {
    TimeSlider
  }
})
export default class MapLayer extends Vue {
  private layerVisible = false;
  private layerOpacity = 100;
  private legendUrl = "";
  private styles = [];
  private selectedStyle = "";
  private isContentVisible = false;
  private contentId = "";
  private layerMetadata: IMetadata[] = [];
  private selectedLayerMetadata: IMetadata = {};
  private currentLayerFilterAllValues: any;
  private metadataDependsOnStyle = false;

  private state: any = {
    filterValues: [],
    opacityExpanded: false,
    firstSelectedTab: "tab-legend",
    rangeValues: null,
    rangeFilterIndex: null,
    yearsValues: [],
    currentUnit: ""
  };

  @Prop({ default: null })
  layerConfig: any;

  @Prop({ default: false })
  isSwipeLayer: boolean;

  /**
   * Created
   */
  private created(): void {
    this.$watch("layerVisible", () => {
      if (this.layerVisible && this.state.rangeValues) {
        document.body.classList.add("isTimeSliderVisible");
      } else {
        document.body.classList.remove("isTimeSliderVisible");
      }
    });

    //Watch locale change
    bus.$on("set_laos_dalam_lang", () => {
      this.loadMetadata(this.$i18n.locale);
    });
  }

  /**
   * Component mounted
   */
  private mounted(): void {
    //Metadata
    this.loadMetadata(this.$i18n.locale);

    this.layerVisible = this.layerConfig.visible;
    this.layerOpacity = this.layerConfig.opacity * 100;
    this.isContentVisible = this.isSwipeLayer || false;
    this.legendUrl = MappingService.getInstance().getLayerLegend(
      this.layerConfig.name
    );
    MappingService.getInstance()
      .getLayerStyles(this.layerConfig.name)
      .then((res: { styles: string[]; defaultStyle: string }) => {
        if (res && res.styles.length) {
          this.styles = res.styles;
          this.selectedStyle = res.defaultStyle || this.styles[0];
          this.setLayerStyle();
        }
      });
    this.contentId = this.layerConfig.name.split(":").join("_");

    if (this.layerConfig.filters && this.layerConfig.filters.length) {
      Vue.set(this.state, "firstSelectedTab", "tab-filter");

      //Get values from layer, if searchLayer property is defined
      if (this.layerConfig.searchLayer)
        this.queryLayer().then((features: any[]) => {
          this.initLayer(features);
        });
      else this.initLayer();
    }

    bus.$on(`arxRangeSliderValue_${this.contentId}`, (rangeInfos: any) => {
      const filterSelects = this.$el.querySelectorAll(".filterSelect");

      if (filterSelects.length > rangeInfos.filterIndex) {
        const select: HTMLSelectElement = filterSelects[
          rangeInfos.filterIndex
        ] as HTMLSelectElement;

        if (select.value !== rangeInfos.value) {
          select.value = rangeInfos.value;
          this.applyFilter(rangeInfos.filterIndex);
        }
      }
    });
  }

  /**
   * Load metadata
   */
  private loadMetadata(lang: string): void {
    this.layerMetadata = [];
    
    ConfigService.getInstance().metadata[lang].forEach((metadata: IMetadata) => {
      if (metadata.layer === this.layerConfig.name) {
        this.layerMetadata.push(metadata);

        if (metadata.style) {
          this.metadataDependsOnStyle = true;
        }
      }
    });

    if (this.layerMetadata && this.layerMetadata.length === 1) {
      this.selectedLayerMetadata = this.layerMetadata[0];
    }
  }

  /**
  * Get Translation
  */
  private getTranslation(label: string): string {
    if(label && isNaN(Number(label))) {
      let tr = label.indexOf('translate.common') === - 1 ? `translate.common.${label.toLowerCase()}` : label.toLowerCase();
      tr = tr.split("'").join("_").split("’").join("_").split("/").join("_");

      if(this.$te(tr)) {
        return this.$t(tr).toString();
      }
    }

    return label;
  }

  /**
  * Get Translation
  */
  private getStyleTranslation(label: string): string {
    let tr = `translate.styles.${label}`;
    tr = tr.split("'").join("_").split("’").join("_").split("/").join("_");
    
    if(this.$te(tr)) {
      return this.$t(tr).toString();
    } else {
      return label;
    }
  }

  /**
   * Init layer
   * @param features
   */
  private initLayer(features: any[] = null): void {
    this.setLayerFilter(features);
    setTimeout(() => {
      this.applyFilter(null);
    }, 200);
  }

  /**
   * Set layer metadata
   * @param params
   */
  private setLayerMetadata(params: any, style = ""): void {
    //Single metadata
    if (this.layerMetadata && this.layerMetadata.length === 1) {
      this.selectedLayerMetadata = this.layerMetadata[0];
    }

    //Metadata depends on style
    else if (style) {
      this.selectedLayerMetadata = this.layerMetadata.find(
        (layerMetadata: IMetadata) =>
          layerMetadata.style &&
          layerMetadata.style.toUpperCase().trim() === style.toUpperCase().trim()
      );
    }

    //Metadata depends on scenario / indicator
    else if (params && params.length) {
      const dimScenario: any = params.find(
        oneParam => oneParam.param === "DIM_SCENARIO"
      );
      const dimIndicator: any = params.find(
        oneParam => oneParam.param === "DIM_INDICATOR"
      );

      let metadata: IMetadata;

      this.layerMetadata.forEach((layerMetadata: IMetadata) => {
        if (!metadata) {
          //Scenario
          let isOK =
            layerMetadata.scenario &&
            dimScenario &&
            dimScenario.value &&
            dimScenario.value
              .toUpperCase()
              .indexOf(layerMetadata.scenario.toUpperCase()) === 0
              ? true
              : false;

          //Indicator
          if (isOK && layerMetadata.indicator) {
            const value: string = dimIndicator.value;
            const indicators: string[] = layerMetadata.indicator
              .split(",")
              .map(item => item.trim().toUpperCase());
            isOK = indicators.indexOf(value.toUpperCase()) !== -1;
          }

          if (isOK) {
            metadata = layerMetadata;
            return false;
          }
        }
      });

      this.selectedLayerMetadata = metadata;
    }
  }

  /**
   * Set layer filers
   * @param features
   */
  private setLayerFilter(features: any[] = null): void {
    if (this.layerConfig.filters) {
      this.layerConfig.filters.forEach((filterConfig: any, i: number) => {
        const oneLayFilter: any = { ...filterConfig };

        switch (filterConfig.type) {
          case "list":
          case "year":
            oneLayFilter.values = this.getValuesFromList(filterConfig, i);

            if (filterConfig.type === "year") {
              oneLayFilter.isYearFilter = true;
              this.state.yearsValues = oneLayFilter.values;
            }
            break;

          case "layer":
          case "layer_year_range":
            oneLayFilter.values = this.getValuesFromLayer(
              filterConfig,
              features
            );
            //this.currentLayerFilterAllValues = oneLayFilter.values;

            if (filterConfig.type === "layer_year_range") {
              this.initA5Values(oneLayFilter, i);
            }

            break;
        }

        // Set labels
        //this.setFilterLabels(oneLayFilter);

        this.state.filterValues.push(oneLayFilter);
      });
    }
  }

  private setFilterLabels(oneLayFilter: any): void {
    if (oneLayFilter && oneLayFilter.values) {
      
      oneLayFilter.values.forEach((filterItem: any) => {
        if (
          filterItem &&
          filterItem.value &&
          isNaN(filterItem.value) &&
          this.$te(`translate.labels.${filterItem.value.toUpperCase()}`)
        ) {
          filterItem.label = this.$t(`translate.labels.${filterItem.value.toUpperCase()}`);
        }
      });

      oneLayFilter.values = oneLayFilter.values.sort((a, b) =>
        a.label > b.label ? 1 : -1
      );
    }
  }

  /**
   * init A5 values
   * @param filterIndex
   */
  private initA5Values(oneLayFilter: any, filterIndex: number) {
    oneLayFilter.isYearFilter = true;
    this.state.rangeValues = {
      value: this.getStartYearFromRange(oneLayFilter.defaultValue),
      data: [],
      range: []
    };

    this.state.yearsValues = oneLayFilter.values.map((rangeItem: any) => {
      const startYear: number = this.getStartYearFromRange(rangeItem.value);

      if (startYear > -1) {
        this.state.rangeValues.range.push({ label: rangeItem.value });
        return { label: rangeItem.value, value: Number(startYear) };
      }
    });

    this.state.rangeFilterIndex = filterIndex;
    this.state.rangeValues = this.getRangesValuesA5(oneLayFilter);
  }

  /**
   * Handle A5 range years
   * @param filterIndex
   * @param minYear
   * @param maxYear
   */
  private handleA5RangeYears(
    oneLayFilter: any,
    filterIndex: number,
    minYear: string = null,
    maxYear: string = null
  ): void {
    //const oneLayFilter: any = {...this.layerConfig.filters[filterIndex]};
    //oneLayFilter.values = this.currentLayerFilterAllValues;
    oneLayFilter.isYearFilter = true;
    this.state.rangeValues = {
      value: this.getStartYearFromRange(oneLayFilter.defaultValue),
      data: [],
      range: []
    };

    /*const minStartYear: number = minYear ? this.getStartYearFromRange(minYear) : -1;
    const minEndYear: number =  minYear ? this.getEndYearFromRange(minYear) : -1;

    const maxStartYear: number = maxYear ? this.getStartYearFromRange(maxYear) : -1;
    const maxEndYear: number = maxYear ? this.getEndYearFromRange(maxYear) : -1;*/

    this.state.yearsValues = this.currentLayerFilterAllValues.map(
      (rangeItem: any) => {
        const startYear: number = this.getStartYearFromRange(rangeItem.value);
        /*const endYear: number = this.getEndYearFromRange(rangeItem.value); 
      let condition: boolean = startYear && startYear > -1;

      if(condition && minStartYear > - 1) {
        condition = condition && startYear >= minStartYear;
      }

      if(condition && minEndYear > - 1) {
        condition = condition && endYear >= minStartYear;
      }

      if(condition && maxStartYear > - 1) {
        condition = condition && startYear <= maxStartYear;
      }

      if(condition && maxEndYear > - 1) {
        condition = condition && endYear <= maxEndYear;
      }*/

        if (startYear > -1) {
          this.state.rangeValues.range.push({ label: rangeItem.value });
          return { label: rangeItem.value, value: Number(startYear) };
        }
      }
    );

    this.state.rangeFilterIndex = filterIndex;
    this.state.rangeValues = this.getRangesValuesA5(oneLayFilter);
  }

  /**
   * Get start year from range
   */
  private getStartYearFromRange(str: string): number {
    const year: string = str && str.length > 3 ? str.substring(0, 4) : "";
    return !isNaN(Number(year)) ? Number(year) : -1;
  }

  /**
   * Get end year from range
   */
  private getEndYearFromRange(str: string): number {
    const year: string = str && str.length > 8 ? str.substring(5, 9) : str;
    return !isNaN(Number(year)) ? Number(year) : -1;
  }

  /**
   * Get values from list
   * @param filterConfig
   * @param filterIndex
   */
  private getValuesFromList(filterConfig: any, filterIndex: number): any[] {
    if (
      (filterConfig.min && filterConfig.max) ||
      (filterConfig.minYear && filterConfig.maxYear)
    ) {
      const min = filterConfig.min || filterConfig.minYear;
      const max = filterConfig.max || filterConfig.maxYear;
      const step = filterConfig.step || filterConfig.stepYear;
      this.state.rangeValues = this.getRangesValues(
        min,
        max,
        step,
        filterConfig.defaultValue
      );
      this.state.rangeFilterIndex = filterIndex;

      return this.getRangeValueLabel(min, max, step);
    } else if (filterConfig.values) {
      return filterConfig.values;
    }

    return [];
  }

  /**
   * Get values from list
   * @param min
   * @param max
   * @param step
   */
  private getRangeValueLabel(min: number, max: number, step: number): any[] {
    const vals: any = [];

    for (let count = min; count <= max; count += step) {
      vals.push({
        label: count,
        value: count,
        isHide: count > min && count % 5 > 0
      });
    }

    return vals;
  }

  /**
   * Get values from list
   * @param min
   * @param max
   * @param step
   * @param defaultValue
   */
  private getRangesValues(
    min: number,
    max: number,
    step: number,
    defaultValue: any
  ): any {
    let rangeVals: any = null;

    rangeVals = { value: defaultValue, data: [], range: [] };

    for (let count = min; count <= max; count += step) {
      rangeVals.data.push(count);
      rangeVals.range.push({
        label: count,
        isHide: count > min && count % 5 > 0
      });
    }

    return rangeVals;
  }

  /**
   * Get values from years range (A5)
   * @param filterConfig
   */
  private getRangesValuesA5(filterConfig: any): any {
    let rangeVals: any = null;

    rangeVals = {
      value: this.getStartYearFromRange(filterConfig.defaultValue),
      data: [],
      range: []
    };
    const modulo: number = this.state.yearsValues.length >= 10 ? 5 : 2;

    this.state.yearsValues.forEach((rangeItem: any, i: number) => {
      if (rangeItem) {
        rangeVals.data.push(rangeItem.value);
        rangeVals.range.push({
          label: rangeItem.label,
          isHide: i < this.state.yearsValues.length && i % modulo > 0
        });
      }
    });

    return rangeVals;
  }

  /**
   * Get values from list
   * @param filterConfig
   * @param
   */
  private getValuesFromLayer(filterConfig: any, features: any[]): any[] {
    let vals: any = [];
    const tempArr: string[] = [];

    if (features) {
      features.forEach((feat: Feature) => {
        let value: string = feat.getProperties()[filterConfig.propertyName];

        if (value) {
          value = value.toString().trim();

          if (tempArr.indexOf(value) === -1) {
            vals.push({ label: value, value: value });
            tempArr.push(value);
          }
        }
      });

      //Sort and remove duplicates
      vals = vals.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    return vals;
  }

  /**
   * Get values from layer
   */
  private async queryLayer(): Promise<any[]> {
    try {
      const features: any[] = await QueryService.queryFeatures(QueryService.createFeatureRequest([this.layerConfig.searchLayer]));
      return Promise.resolve(features);
      
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * On btn expand / collapse click
   */
  private onExpandBtnClick(event: any): void {
    this.isContentVisible = !this.isContentVisible;

    //Dispatch resize to fix tabs default selected tab
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }

  /**
   * Show / hide layer
   */
  private setLayerVisibility() {
    this.layerVisible = !this.layerVisible;
    MappingService.getInstance().setLayerVisibility(
      this.layerConfig.name,
      this.layerVisible
    );

    if (!this.layerVisible) Vue.set(this.state, "opacityExpanded", false);
  }

  /**
   * On opacity change
   */
  private onOpacityChange() {
    const layerName: string = this.isSwipeLayer
      ? MappingService.swipeLayerName
      : this.layerConfig.name;
    MappingService.getInstance().setLayerOpacity(
      layerName,
      this.layerOpacity / 100
    );
  }

  private handleTimeSliderMounted(): void {
    const ts: TimeSlider = this.$refs.timeSlider as TimeSlider;

    if (ts) {
      ts.setVal(Number(this.state.rangeValues.value));
    }
  }

  /**
   * Set year range
   * @param filterItemIndex
   */
  private setYearsRange(filterItemIndex: number): void {
    const filterConfig = this.state.filterValues[filterItemIndex];
    const filterSelects = this.$el.querySelectorAll(".filterSelect");
    const yearSelect: HTMLSelectElement = filterSelects[
      this.state.rangeFilterIndex
    ] as HTMLSelectElement;

    let doInitTS = false;
    let min: any = null;
    let max: any = null;
    let currentVal = -1;

    //Range year (A5)
    if (filterConfig && filterConfig.type === "layer_year_range") {
      //this.handleA5RangeYears(filterConfig, filterItemIndex);
      //min = filterConfig.defaultValue;
      const selectedYearLabel: string =
        yearSelect.options[yearSelect.selectedIndex] &&
        yearSelect.options[yearSelect.selectedIndex].textContent
          ? yearSelect.options[yearSelect.selectedIndex].textContent
          : "";

      if (selectedYearLabel) {
        //} && selectedYearLabel.length === 9){
        currentVal = Number(yearSelect.value);
      }

      doInitTS = true;
    }
    //Years filer
    else if (filterConfig && filterConfig.isYearFilter) {
      currentVal = Number(yearSelect.value);
      doInitTS = true;
    }

    //Range year (A4)
    else if (
      filterConfig &&
      filterConfig.values &&
      filterConfig.values.length &&
      filterConfig.values[0].minYear &&
      filterConfig.values[0].maxYear
    ) {
      if (filterSelects && filterSelects.length > filterItemIndex) {
        const targetSelect = filterSelects[
          filterItemIndex
        ] as HTMLSelectElement;
        const selectedIndex = targetSelect.selectedIndex;

        min = filterConfig.values[selectedIndex].minYear;
        max = filterConfig.values[selectedIndex].maxYear;

        // Number year (A4)
        if (!isNaN(min)) {
          const step = filterConfig.values[selectedIndex].stepYear;
          this.state.yearsValues = this.getRangeValueLabel(
            Number(min),
            Number(max),
            step
          );
          this.state.rangeValues = this.getRangesValues(
            Number(min),
            Number(max),
            step,
            filterConfig.defaultValue
          );

          this.state.rangeValues.value = min;
          doInitTS = true;
        }
      }
    }

    if (doInitTS) {
      this.initTimeSlider(min, currentVal);
    }
  }

  /**
   * Init time slider
   */
  private initTimeSlider(min: number, currentValue = -1): void {
    const filterSelects = this.$el.querySelectorAll(".filterSelect");
    const yearSelect: HTMLSelectElement = filterSelects[
      this.state.rangeFilterIndex
    ] as HTMLSelectElement;

    const ts: TimeSlider = this.$refs.timeSlider as TimeSlider;
    if (ts) {
      ts.setVal(currentValue > -1 ? currentValue : min);
    }

    setTimeout(() => {
      if (min && yearSelect.value !== min.toString()) {
        yearSelect.value = min.toString();
      }
      this.applyFilter(null);
    }, 200);
  }

  /**
   * Set layer style
   */
  private setLayerStyle() {
    const layerName: string = this.isSwipeLayer
      ? MappingService.swipeLayerName
      : this.layerConfig.name;
    MappingService.getInstance().setLayerParams(layerName, [
      { param: "STYLES", value: this.selectedStyle }
    ]);
    this.setLayerLegend(layerName, this.selectedStyle);

    if (this.metadataDependsOnStyle) {
      this.setLayerMetadata(null, this.selectedStyle);
    }
  }

  /**
   * Set layer legend
   * @param layerName
   * @param styleName
   */
  private setLayerLegend(layerName: string, styleName: string) {
    this.legendUrl = `${
      ConfigService.getInstance().config.map.WMSBaseURL
    }?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}&style=${styleName}`;
    this.setCurrentUnit(styleName);

    this.selectedStyle = styleName;
  }

  /**
   * Set current unit
   */
  private setCurrentUnit(style: string): void {
    if (this.layerConfig && this.layerConfig.units) {
      const unitObj: any = this.layerConfig.units.find(
        (item: any) => item.style === style
      );

      if (unitObj) {
        this.state.currentUnit = unitObj.unit;
      }
    }
  }

  /**
   * Download
   */
  private download(): void {
    let url: string;
    if (this.layerConfig.downloadable && this.layerConfig.geoTiffDownloadUrl) {
      url = this.createTifDownloadUrl();
    } else if (this.layerConfig.downloadable) {
      url = `${
        ConfigService.getInstance().config.map.WFSBaseURL
      }?request=GetFeature&typename=${
        this.layerConfig.name
      }&version=1.1.1&outputFormat=shape-zip`;
    }

    if (url) {
      bus.$emit("openDownloadLayer", {
        name: this.layerConfig.label,
        url: url
      });
    }
  }

  /**
   * Create tif download url
   */
  private createTifDownloadUrl(): string {
    let url: string = this.layerConfig.geoTiffDownloadUrl;
    const filterSelects = this.$el.querySelectorAll(".filterSelect");

    if (filterSelects && filterSelects.length > 0) {
      //First check if scenarioFilterIndex exists
      filterSelects.forEach((select: any, i: number) => {
        const filterConfig = this.state.filterValues[i];

        if (filterConfig.scenarioFilterIndex) {
          const selectedIndex = (select as HTMLSelectElement).selectedIndex;
          const value: string = select.value;
          const label: string =
            select.options[selectedIndex] &&
            select.options[selectedIndex].textContent
              ? select.options[selectedIndex].textContent
              : "";
          const scenarioVal: string = this.getA5Scenario(
            filterConfig.scenarioFilterIndex,
            (filterSelects[
              filterConfig.scenarioFilterIndex
            ] as HTMLSelectElement).value,
            label
          );

          if (scenarioVal) {
            url = url.replace(
              `{${filterConfig.scenarioFilterIndex}}`,
              scenarioVal
            );
          }
        }
      });

      //Other filters
      filterSelects.forEach((select: any, i: number) => {
        const filterConfig = this.state.filterValues[i];
        let value: string = select.value;

        if (filterConfig.type === "layer_year_range") {
          const selectedIndex = (select as HTMLSelectElement).selectedIndex;
          value =
            select.options[selectedIndex] &&
            select.options[selectedIndex].textContent
              ? select.options[selectedIndex].textContent
              : "";
        }

        url = url.replace(`{${i}}`, value);
      });
    }
    url = `${
      ConfigService.getInstance().config.map.geoTiffDownloadBaseURL
    }${url}`;
    return url;
  }

  /**
   * Apply filter
   * @param filterItemIndex
   */
  public applyFilter(filterItemIndex: number) {
    //Set years
    if (filterItemIndex && this.state.filterValues[filterItemIndex]) {
      this.setYearsRange(filterItemIndex);
    }

    const layerName: string = this.isSwipeLayer
      ? MappingService.swipeLayerName
      : this.layerConfig.name;
    const ts: TimeSlider = this.$refs.timeSlider as TimeSlider;

    const filterSelects = this.$el.querySelectorAll(".filterSelect");

    if (filterSelects && filterSelects.length > 0) {
      const viewparamsStr: string[] = [];
      let params: any[] = [];

      filterSelects.forEach((select: any, i: number) => {
        const oneFilterItem: any = this.layerConfig.filters[i];
        const oneFilterItemValues: any = this.state.filterValues[i];
        const selectValue: string = (select as HTMLSelectElement).value;
        const selectedIndex: number = (select as HTMLSelectElement)
          .selectedIndex;
        const selectLabel: string =
          select.options[selectedIndex] &&
          select.options[selectedIndex].textContent
            ? select.options[selectedIndex].textContent
            : "";

        if (this.layerConfig.filterType === "viewparams") {
          viewparamsStr.push(
            `${oneFilterItemValues.propertyName}:${selectValue}`
          );
        } else if (this.layerConfig.filterType === "params") {
          const oneParam = { ...oneFilterItemValues.condition };
          oneParam.value = oneParam.value.replace(
            "{val}",
            oneFilterItem && oneFilterItem.type === "layer_year_range"
              ? selectLabel
              : selectValue
          );
          const selectedValueConfig = oneFilterItemValues.values.find(
            i => i.value === selectValue
          );

          if (ts && oneFilterItem && oneFilterItem.min && oneFilterItem.max) {
            ts.setVal(Number(selectValue));
          }

          if (selectedValueConfig && selectedValueConfig.style) {
            params.push({ param: "STYLES", value: selectedValueConfig.style });
            this.setLayerLegend(layerName, selectedValueConfig.style);
          }

          //Special case (A5)
          if (oneFilterItem && oneFilterItem.scenarioFilterIndex) {
            const styleParam: any = params.find(
              (item: any) => item.param === "STYLES"
            );

            if (styleParam) {
              params = params.filter((item: any) => item.param !== "STYLES");
              params.push(styleParam);
            }

            const scenarioVal: string = this.getA5Scenario(
              oneFilterItem.scenarioFilterIndex,
              params[oneFilterItem.scenarioFilterIndex].value,
              oneParam.value
            );

            if (scenarioVal) {
              params[oneFilterItem.scenarioFilterIndex].value = scenarioVal;
            }
          }

          params.push(oneParam);
        }
      });

      if (this.layerConfig.filterType === "viewparams") {
        MappingService.getInstance().setLayerViewparams(
          layerName,
          viewparamsStr.join(";")
        );
      } else if (this.layerConfig.filterType === "params") {
        MappingService.getInstance().setLayerParams(layerName, params);
      }

      //Set layer metadata
      this.setLayerMetadata(params);
    }
  }

  /**
   * Handle A5 scenario
   */
  private getA5Scenario(
    scenarioFilterIndex: number,
    oldValue: string,
    yearRange: string
  ): string {
    let scenarioVal = oldValue;
    const scenarioConfig: any = this.layerConfig.filters[scenarioFilterIndex];
    const endYear: number = this.getEndYearFromRange(yearRange);

    if (new Date().getFullYear() > endYear) {
      scenarioVal = scenarioConfig.defaultValue;
    }

    return scenarioVal;
  }
}
