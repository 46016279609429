



import { Component, Vue, Prop } from "vue-property-decorator";
import { ConfigService } from "../../../../services/configService";
import { IDataset, IVectorLayer } from "../../../../interfaces/IMapConfig";
import MapLayer from "@/components/ArxMap/MapLayer/MapLayer.vue";

@Component({
  components: {
    MapLayer
  }
})
export default class FilterLayer extends Vue {
  private datasets: IDataset[] = [];
  private selectedDatasetIndex = -1;
  private selectedDataset: IDataset = null;
  private selectedLayerIndex = -1;
  public selectedLayer: IVectorLayer | any = null;

  @Prop({ default: false })
  isSwipeLayer: boolean;

  /**
   * Created
   */
  private mounted(): void {
    const datasetsConfig = ConfigService.getInstance().config.map.datasets;

    for (const key in datasetsConfig) {
      const ds: IDataset = datasetsConfig[key];
      if (ds.comparable) {
        this.datasets.push({
          ...ds,
          ...{
            toLowerCase: () => this.$t(ds.label).toString().toLowerCase(),
            toString: () => this.$t(ds.label).toString()
          }
        });
      }
    }
  }

  /**
   * On change dataset
   */
  private onChangeDataset(): void {
    if(this.datasets && this.datasets.length > 0 && this.selectedDatasetIndex >= 0) {
      this.selectedDataset = this.datasets[this.selectedDatasetIndex];

      /*if(this.selectedDataset) {
        const layers = [];

        this.selectedDataset.layers.forEach(x => {
          layers.push({
            ...x,
            ...{
              toLowerCase: () => this.$t(x.label).toString().toLowerCase(),
              toString: () => this.$t(x.label).toString()
            }
          });
        });

        this.selectedDataset.layers = layers;
      }*/
    }
  }

  /**
   * On change layer
   */
  private onChangeLayer(): void {
    if( this.selectedDataset &&  this.selectedDataset.layers &&  this.selectedDataset.layers.length > 0) {
      this.selectedLayer = this.selectedDataset.layers[this.selectedLayerIndex];
    }
  }

  /**
   * Call filter
   */
  public callFilter(): void {
    const mapLayer: MapLayer = this.$refs["filterLayer"] as MapLayer;

    if (mapLayer && mapLayer.layerConfig && mapLayer.layerConfig.filters) {
      mapLayer.applyFilter(null);
    }
  }
}
