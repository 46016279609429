



import { Component, Vue, Prop } from "vue-property-decorator";
import VueSlideBar from "vue-slide-bar";
import { bus } from "../../../main";

@Component({
  components: {
    VueSlideBar
  }
})
export default class TimeSlider extends Vue {
  private state: any = {
    currentValue: 0,
    isPlaying: false
  };

  private timer: any;

  @Prop({ default: null })
  private id: string;

  @Prop({ default: null })
  private slider: any;

  @Prop({ default: null })
  private filterIndex: number;

  /**
   * Handle play pause
   */
  private playOrPause(): void {
    this.state.isPlaying = !this.state.isPlaying;
    const firstVal = this.slider.data[0];
    const lastVal = this.slider.data[this.slider.data.length - 1];

    if (this.state.isPlaying) {
      this.state.currentValue =
        this.state.currentValue === lastVal
          ? firstVal
          : this.slider.data[
              this.slider.data.indexOf(this.state.currentValue) + 1
            ];

      this.timer = setInterval(() => {
        this.state.currentValue = this.slider.data[
          this.slider.data.indexOf(this.state.currentValue) + 1
        ];

        if (this.state.currentValue === lastVal) {
          //Go to first position
          setTimeout(() => {
            this.state.currentValue = this.slider.data[0];
          }, 2000);
        }
      }, 2000);
    } else {
      clearInterval(this.timer);
    }
  }

  /**
   * Handle slider change
   */
  private callbackRange(val): void {
    const sliderVal: any = {
      value: isNaN(val.label)
        ? this.getStartYearFromRange(val.label)
        : val.label,
      filterIndex: this.filterIndex
    };
    bus.$emit(`arxRangeSliderValue_${this.id}`, sliderVal);
  }

  /**
   * Get start year from range
   */
  private getStartYearFromRange(str: string): number {
    const year: string = str && str.length > 3 ? str.substring(0, 4) : "";
    return !isNaN(Number(year)) ? Number(year) : -1;
  }

  /**
   * Set value
   */
  public setVal(val: number): void {
    if (Number(this.state.currentValue) !== val) {
      this.state.currentValue = val;
    }
  }
}
