



import { Component, Vue } from "vue-property-decorator";
import { bus } from "../../../main";

@Component
export default class MapCoords extends Vue {
  private coords = "";

  private mounted(): void {
    this.coords = "";

    bus.$on("ArxMapCoords", (coords: string) => {
      this.coords = coords;
    });
  }
}
