



import { Component, Watch, Vue } from "vue-property-decorator";
import ArxTools from "@/components/Header/ArxTools/ArxTools.vue";
import { Route } from "vue-router";
import { ConfigService } from "../../services/configService";
import LocaleSwitcher from "@/components/Header/LocaleSwitcher/LocaleSwitcher.vue";
import { IThematicConfig } from "@/interfaces/IThematicConfig";

@Component({
  components: {
    ArxTools, LocaleSwitcher
  }
})
export default class Header extends Vue {
  private thematicLabel: string | string[] = "";
  private responsiveMenuVisible = false;
  private arxToolsVisible = false;
  private thematicLabelVisible = false;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newRoute: Route) {
    this.arxToolsVisible = newRoute.name && (newRoute.name === "MapWithParam" || newRoute.name === "MapWithLatLngParam");
    this.thematicLabelVisible =
      newRoute.name &&
      (newRoute.name === "MapWithParam" ||
        newRoute.name === "ThematicWithParam");
    this.thematicLabel = this.findThematic(newRoute.params.thematic);
    this.handleMenuVisibility();
  }

  private mounted(): void {
    this.thematicLabel = this.findThematic(ConfigService.getInstance().defaultThematic);
    // this.$router.push({ name: "Home" });

    this.handleMenuVisibility();

    window.onresize = () => {
      this.handleMenuVisibility();
    };
  }

  private findThematic(id: string): string {
    const themConfig: IThematicConfig = ConfigService.getInstance().config.thematics.find( (t: IThematicConfig) => t.id === id);
    return themConfig ? this.$t(themConfig.label).toString() : id;
  }

  /**
   * Handle responsive menu visibility
   */
  private handleMenuVisibility(): void {
    this.responsiveMenuVisible = !(window.innerWidth <= 600);
  }
}
