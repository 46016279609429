



import { Component, Vue } from "vue-property-decorator";
import { MappingService } from "../../../services/mappingService";

@Component
export default class MapZoom extends Vue {
  public zoomInitialView(): void {
    MappingService.getInstance().zoomInitialView();
  }

  public zoomOut(): void {
    MappingService.getInstance().zoom(-1);
  }

  public zoomIn(): void {
    MappingService.getInstance().zoom(1);
  }
}
