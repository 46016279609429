



import { Component, Vue } from "vue-property-decorator";
import ArxBar from "./templates/ArxBar";
import ArxPie from "./templates/ArxPie";
import ArxLine from "./templates/ArxLine";
import StatsLayer from "./templates/StatsLayer/StatsLayer.vue";
import { Helper } from "./helper";
import ExpandableCard from "../../widgets/Layers/templates/ExpandableCard/ExpandableCard.vue";
import { IDataset } from "../../interfaces/IMapConfig";
import { ConfigService } from "@/services/configService";
import { QueryService } from "@/services/queryService";
import { UtilsService } from "@/services/utilsService";

@Component({
  components: {
    ArxBar,
    ArxPie,
    ArxLine,
    ExpandableCard,
    StatsLayer
  }
})
export default class Statistics extends Vue {
  private state = {
    isLoading: true
  };

  private helper: Helper = new Helper();
  private datasets: IDataset[] = [];
  private isMobileScreen = false;

  /**
   * Component create
   */
  private async created(): Promise<any> {
    try {
      // Get the list of provinces and districts
      this.state.isLoading = true;
      if(!ConfigService.getInstance().provinces || !ConfigService.getInstance().provinces.length) {
        ConfigService.getInstance().provinces = await QueryService.queryFeatures(QueryService.createFeatureRequest([ConfigService.getInstance().config.map.provincesLayer.name]));
      }

      if(!ConfigService.getInstance().districts || !ConfigService.getInstance().districts.length) {
        ConfigService.getInstance().districts = await QueryService.queryFeatures(QueryService.createFeatureRequest([ConfigService.getInstance().config.map.districtsLayer.name]));
      }
      
      this.state.isLoading = false;
    }
    catch(err) {
      console.log(err);
      this.state.isLoading = false;
    }
  }

  /**
   * Component mounted
   */
  private mounted(): void {
    this.isMobileScreen = UtilsService.getInstance().isMobileScreen();

    const d1 = this.helper.getA1Dataset();
    const d2 = this.helper.getA2Dataset();
    const d3 = this.helper.getA3Dataset();
    const d4 = this.helper.getA4Dataset();
    const d5 = this.helper.getA5Dataset();
    const d6 = this.helper.getA6Dataset();
    const d7 = this.helper.getA7Dataset();

    if(d1) {
      this.datasets.push(d1);
    }
    
    if(d2) {
      this.datasets.push(d2);
    }

    if(d3) {
      this.datasets.push(d3);
    }

    if(d4) {
      this.datasets.push(d4);
    }

    if(d5) {
      this.datasets.push(d5);
    }

    if(d6) {
      this.datasets.push(d6);
    }

    if(d7) {
      this.datasets.push(d7);
    }
  }
}
