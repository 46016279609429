



import { Component, Vue } from "vue-property-decorator";
import FilterLayer from "@/widgets/MapComparison/templates/FilterLayer/FilterLayer.vue";
import ArxSplitter from "@/widgets/MapComparison/templates/ArxSplitter/ArxSplitter.vue";
import ExpandableCard from "@/widgets/Layers/templates/ExpandableCard/ExpandableCard.vue";
import { IVectorLayer } from "../../interfaces/IMapConfig";
import { MappingService } from "../../services/mappingService";

@Component({
  components: {
    FilterLayer,
    ArxSplitter,
    ExpandableCard
  }
})
export default class MapComparison extends Vue {
  private swipeValue = 50;
  private compareOn = false;
  private leftFilterLayer: FilterLayer = null;
  private rightFilterLayer: FilterLayer = null;
  private swipeDivDragEnabled = false;
  /**
   * Created
   * */
  private created(): void {
    /*this.$watch(
      "$refs.leftLayer.selectedLayer",
      () => {
        this.onSelectLeftLayer();
      }
    );

    this.$watch(
      "$refs.rightLayer.selectedLayer",
      () => {
        this.onSelectRightLayer();
      }
    );*/
  }

  /**
   * Mounted
   */
  private mounted(): void {
    this.swipeValue = 50;
    this.compareOn = false;
    this.leftFilterLayer = this.$refs["leftLayer"] as FilterLayer;
    this.rightFilterLayer = this.$refs["rightLayer"] as FilterLayer;
  }

  /**
   * Drag element
   * @param elmnt
   */
  private dragElement(elmnt: HTMLElement): void {
    let pos1 = 0,
      pos3 = 0;

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos3 = e.clientX;
      const left: number = elmnt.offsetLeft - pos1;

      if (left > 0) {
        elmnt.style.left = `${left}px`;
      }

      MappingService.getInstance().setSwipeValue(
        (left / window.innerWidth) * 100
      );
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    elmnt.onmousedown = dragMouseDown;
  }

  /**
   * Handle swipe
   */

  private onSwipe(): void {
    MappingService.getInstance().setSwipeValue(this.swipeValue);
  }

  /**
   * Init swipe div
   */
  private initSwipeDiv(): void {
    const arxSwipeDiv: HTMLDivElement = document.getElementById(
      "arxSwipeDiv"
    ) as HTMLDivElement;
    if (arxSwipeDiv && !this.swipeDivDragEnabled) {
      this.dragElement(arxSwipeDiv);
      this.swipeDivDragEnabled = true;
    }

    arxSwipeDiv.style.left = `${window.innerWidth / 2}px`;
  }

  /**
   *
   */
  private compare(): void {
    this.initSwipeDiv();

    const leftLayer: IVectorLayer | any = this.leftFilterLayer.selectedLayer;
    const rightLayer: IVectorLayer | any = this.rightFilterLayer.selectedLayer;

    MappingService.getInstance().handleSwipe(leftLayer, rightLayer);

    //Call filter
    (this.$refs["leftLayer"] as FilterLayer).callFilter();
    (this.$refs["rightLayer"] as FilterLayer).callFilter();

    //Set swipe value
    this.onSwipe();

    this.compareOn = true;
  }

  /**
   * Cancel swipe
   */
  private cancel(): void {
    /*(this.$refs["leftLayer"] as FilterLayer).unselect();
    (this.$refs["rightLayer"] as FilterLayer).unselect();*/
    MappingService.getInstance().cancelSwipe();
    this.compareOn = false;
  }

  /**
   * Handle select left layer
  
  private onSelectLeftLayer(): void {
    this.leftLayer = (this.$refs["leftLayer"] as FilterLayer).selectedLayer;
  }*/

  /**
   * Handle select right layer
   
  private onSelectRightLayer(): void {
    this.leftLayer = (this.$refs["leftLayer"] as FilterLayer).selectedLayer;
  }*/

  /**
   * Handle sidebar close
   */
  public handleSideBarClose(): void {
    this.cancel();
  }
}
