import { MappingService } from "../../services/mappingService";
import { ConfigService } from "../../services/configService";
import TileLayer from "ol/layer/Tile";
import { IDataset } from "../../interfaces/IMapConfig";

export class Helper {
  /**
   * Get Dataset
   * @param dataset
   * @returns
   */
  private getDataset(dataset: IDataset): IDataset {
    if (!dataset) {
      return null;
    }

    const layers: any[] = [];
    const mapLayers = MappingService.getInstance().getMapLayers();
    const configLayers: any[] = dataset.layers;

    configLayers.forEach(layerConfig => {
      const mapLayer: TileLayer = mapLayers[layerConfig.name]
        ? mapLayers[layerConfig.name].layer
        : null;
      const layer = { ...layerConfig };

      if (mapLayer) {
        layer.visible = mapLayer.getVisible();
        layers.push(layer);
      }
    });

    return {
      label: dataset.label,
      thematics: dataset.thematics,
      comparable: dataset.comparable,
      layers: layers
    };
  }

  /**
   * Get A1 Dataset
   */
  public getA1Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A1);
  }

  /**
   * Get A2 Dataset
   */
  public getA2Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A2);
  }

  /**
   * Get A3 Dataset
   */
  public getA3Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A3);
  }

  /**
   * Get A4 Dataset
   */
  public getA4Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A4);
  }

  /**
   * Get A5 Dataset
   */
  public getA5Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A5);
  }

  /**
   * Get A6 Dataset
   */
  public getA6Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A6);
  }

  /**
   * Get A4 Dataset
   */
  public getA7Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A7);
  }

  /**
   * Get A4 Dataset
   */
  public getA8Dataset(): IDataset {
    return this.getDataset(ConfigService.getInstance().config.map.datasets.A8);
  }
}
