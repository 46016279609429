import { Vue, Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Bar, mixins } from "vue-chartjs";

@Component({
  extends: Bar,
  mixins: [mixins.reactiveProp]
})
export default class ArxBar extends Mixins(mixins.reactiveProp, Bar) {
  @Prop({ default: null })
  chartData: any;

  @Prop({ default: null })
  chartOptions: any;

  @Watch("chartData", {
    immediate: false,
    deep: true
  })
  chartDataChanged(newVal, oldVal) {
    this.renderChart(newVal, this.chartOptions);
  }

  private mounted(): void {
    this.renderChart(this.chartData, this.chartOptions);
  }
}
