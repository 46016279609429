



import { Component, Vue } from "vue-property-decorator";
import { QueryService } from "../../services/queryService";
import { ConfigService } from "../../services/configService";
import { ISearchConfig } from "../../interfaces/ISearchConfig";
import { MappingService } from "@/services/mappingService";
import { bus } from "../../main";

interface State {
  expanded: boolean;
  searchAllResults: any;
  selectedSearchItem: any;
  isLoading: boolean;
}

@Component
export default class Search extends Vue {
  private state: State = {
    expanded: false,
    searchAllResults: [],
    selectedSearchItem: null,
    isLoading: false
  };

  /**
   * Created
   */
  private created(): void {
    const searchConfig: ISearchConfig[] = ConfigService.getInstance().config
      .search;
    const searchLayers: string[] = searchConfig.map((config: ISearchConfig) => {
      return config.layer;
    });

    this.state.isLoading = true;
    QueryService.queryFeatures(
      QueryService.createFeatureRequest(searchLayers)
    ).then((features: any[]) => {
      this.constructSearchData(features);
      this.state.isLoading = false;
    });
  }

  /**
   * Construct search data
   */
  private constructSearchData(features: any[]): void {
    const searchConfig: ISearchConfig[] = ConfigService.getInstance().config
      .search;
    const searchLayersConf: any = {};
    const duplicatesArr: any = {};

    searchConfig.forEach((config: ISearchConfig) => {
      const layerName: string =
        config.layer.indexOf(":") > -1
          ? config.layer.split(":")[1]
          : config.layer;

      if (!searchLayersConf[layerName]) searchLayersConf[layerName] = config;
    });

    features.forEach((feat: any) => {
      const layerName: string = feat.getId().split(".")[0];
      let label: string = searchLayersConf[layerName].displayField;

      for (const key in feat.getProperties()) {
        label = label.replaceAll(`{${key}}`, feat.getProperties()[key]);
      }

      if (!this.state.searchAllResults[layerName])
        this.state.searchAllResults[layerName] = [];

      if (!duplicatesArr[layerName]) duplicatesArr[layerName] = [];

      if (duplicatesArr[layerName].indexOf(label) === -1) {

        this.state.searchAllResults.push({
          label: `${this.$t(searchLayersConf[layerName].label)} - ${label}`,
          feature: feat,
          layerName: layerName
        });

        duplicatesArr[layerName].push(label);
      }
    });

    //Sort data
    this.state.searchAllResults.sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  /**
   * Handle select search item
   */
  private onSelectSearchItem(): void {
    MappingService.getInstance().zoomToGeom(
      this.state.selectedSearchItem.feature
    );
    bus.$emit("ArxShowPopup", this.state.selectedSearchItem.feature);
  }
}
