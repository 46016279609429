



import { Component, Vue } from "vue-property-decorator";
import { bus } from "../../../main";

@Component
export default class ArxTools extends Vue {
  private LAYERS = "layers";
  private MAP_COMPARISON = "mapComparison";
  private STATISTICS = "statistics";
  private mapComparisonVisible = true;

  private state = {
    currentTool: ""
  };

  /**
   * Created
   */
  private created(): void {
    bus.$on("unselectTools", () => {
      this.unselectTools();
    });
  }

  /**
   * Show layers as default tool
   */
  private mounted(): void {
    this.showTool(this.LAYERS);
  }

  /**
   * Show tool
   * @param tool
   */
  private showTool = (tool: string): void => {
    Vue.set(this.state, "currentTool", tool);
    bus.$emit("showTool", tool);
  };

  /**
   * Unselect tools
   * @param tools
   */
  private unselectTools = (): void => {
    Vue.set(this.state, "currentTool", "");
  };
}
