



import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ExpandableCard extends Vue {
  private isContentVisible = false;

  @Prop({ default: false })
  expandOnOpen: boolean;

  /**
   * Component mounted
   */
  private mounted(): void {
    this.isContentVisible = this.expandOnOpen || false;
  }

  /**
   * On btn expand / collapse click
   */
  private onExpandBtnClick(event: any): void {
    this.isContentVisible = !this.isContentVisible;

    //Dispatch resize to fix time slider default value
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }
}
