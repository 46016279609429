import WMSGetFeatureInfo from "ol/format/WMSGetFeatureInfo";
import { GeoJSON, WFS } from "ol/format";
import { ConfigService } from "./configService";
import {
  and as andFilter,
  equalTo as equalToFilter
} from 'ol/format/filter';
import IFilterAttributeValue from "../interfaces/IFilterAttributeValue"


export class QueryService {

  /**
   * Create layer filters
   * @param filters
   */
  private static createLayerFilter(filters: IFilterAttributeValue[]): any[] {
    if (filters && filters.length) {
      return filters.map( (filter: IFilterAttributeValue) => {
        return equalToFilter(filter.attribute, filter.value)
      });
    }

    return [];
  }

  /**
   * Create feature request
   * @param featureTypes
   * @param maxFeatures
   * @returns
   */
  public static createFeatureRequest(
    featureTypes: string[],
    maxFeatures: number = null,
    filters: IFilterAttributeValue[] = null
  ): Node {
    const conditions: any[] = QueryService.createLayerFilter(filters);
    let filter: any = null;

    if (conditions.length > 1) {
      filter = andFilter(...conditions);
    } else if (conditions.length === 1) {
      filter = conditions[0];
    }

    return new WFS().writeGetFeature({
      srsName: ConfigService.getInstance().config.map.projection,
      featureNS: "",
      featurePrefix: "",
      featureTypes: featureTypes,
      outputFormat: "application/json",
      maxFeatures: maxFeatures || 999999,
      filter: filter
    });
  }
  
  /**
   * Query features
   * @param featureRequest
   * @returns
   */
  public static async queryFeatures(featureRequest: Node): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      fetch(ConfigService.getInstance().config.map.WFSBaseURL, {
        method: "POST",
        body: new XMLSerializer().serializeToString(featureRequest)
      })
        .then(response => response.json())
        .then(json => resolve(new GeoJSON().readFeatures(json)))
        .catch((err: any) => {
          resolve(null);
        });
    });
  }

  /**
   * Query SQL view features
   * @param featureRequest
   * @returns
   */
   public static async querySQLViewFeatures(layer: string, filters: IFilterAttributeValue[] = null): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      const viewparams: string[] = filters && filters.length ? 
      filters.map( (filter: IFilterAttributeValue) => {
        return `${filter.attribute}:${filter.value}`;
      }) : [];
      const url = `${ConfigService.getInstance().config.map.WFSBaseURL}?service=wfs&request=GetFeature&typeNames=${layer}&viewparams=${viewparams.join(';')}&outputFormat=application/json`;
      fetch(url)
        .then(response => response.json())
        .then(json => resolve(new GeoJSON().readFeatures(json)))
        .catch((err: any) => {
          resolve(null);
        });
    });
  }

  /**
   * Query WMS
   * @param view
   * @param wmsSource
   * @param coordinates
   * @returns
   */
  public static queryWMS(view, wmsSource, coordinates): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      const viewResolution = /** @type {number} */ view.getResolution();
      const url = wmsSource.getFeatureInfoUrl(
        coordinates,
        viewResolution,
        ConfigService.getInstance().config.map.projection,
        { INFO_FORMAT: "application/vnd.ogc.gml" }
      );
      if (url) {
        fetch(url)
          .then(response => response.text())
          .then((result: any) => {
            try {
              const parser = new WMSGetFeatureInfo();
              resolve(parser.readFeatures(result));
            } catch (err) {
              console.error(err);
              resolve(null);
            }
          });
      }
    });
  }
}
