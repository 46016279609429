



import { Component, Vue } from "vue-property-decorator";
import { Helper } from "./helper";
import MapZoom from "@/components/ArxMap/MapZoom/MapZoom.vue";
import MapCoords from "@/components/ArxMap/MapCoords/MapCoords.vue";
import { PopupService } from "../../services/popupService";
import { MappingService } from "../../services/mappingService";
import { bus } from "../../main";

@Component({
  components: {
    MapZoom,
    MapCoords
  }
})
export default class ArxMap extends Vue {
  private previousDisabled = true;
  private nextDisabled = false;
  public helper: Helper = new Helper();

  private created(): void {

    PopupService.getInstance().initLocales(this);

    bus.$on("ArxHidePopup", () => {
      this.previousDisabled = true;
      this.nextDisabled = false;
    });
  }

  private mounted(): void {
    this.helper.initializeMap();
  }

  /**
   * Handle previous button
   */
  private showPopupPreviousFeature(): void {
    this.showPopup(PopupService.getInstance().currentPopupIndex - 1);
  }

  /**
   * Handle next button
   */
  private showPopupNextFeature(): void {
    this.showPopup(PopupService.getInstance().currentPopupIndex + 1);
  }

  /**
   * Show popup
   */
  private showPopup(index: number): void {
    const items = PopupService.getInstance().currentPopupFeatures;

    if (index >= 0 && items.length > index) {
      MappingService.getInstance()
        .queryFeaturesForPopup(
          items[index].source,
          items[index].config,
          items[index].coordinates,
          items[index].config.statsConfig,
        )
        .then(() => {
          PopupService.getInstance().currentPopupIndex = index;

          this.previousDisabled = index <= 0;
          this.nextDisabled = index >= items.length - 1;
        });
    }
  }
}
