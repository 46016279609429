



import { Component, Vue } from "vue-property-decorator";
import { bus } from "../../../main";

@Component
export default class LocaleSwitcher extends Vue {
    private selectedLanguage = "en";
    private localStorage = window.localStorage;

    private created(): void { 
        const storedLang: string = this.localStorage.getItem('laos_dalam_lang');
        
        if(storedLang) {
            this.switchLanguage(storedLang);
        } else {
            let userLang: string = navigator.language || (navigator as any).userLanguage;

            if(userLang) {
                userLang = userLang.split('-')[0];

                if(userLang === 'en' || userLang === 'lo') {
                    this.switchLanguage(userLang);
                }
            }
        }        
    }

    /**
     * Get selected icon
     */
    private getSelectedIcon(): string {
        return require(`@/assets/flags/${this.selectedLanguage}.png`);
    }

    /**
     * Switch language
     */
    private switchLanguage(lang: string): void {
        this.selectedLanguage = lang;
        this.$i18n.locale = lang;
        this.localStorage.setItem('laos_dalam_lang', lang);
        bus.$emit("set_laos_dalam_lang");
    }
}
