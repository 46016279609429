



import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class ThematicItem extends Vue {
  private currentThematic = "";

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newRoute: Route) {
    if (newRoute.name === "ThematicWithParam") {
      this.currentThematic = newRoute.params.thematic;
    }
  }

  /**
   * Get image
   */
  private getImage(image: string): any {
    return require(`@/assets/thematics/en/${this.currentThematic.toLowerCase()}/${image}`);
  }
}
