



import { Component, Vue, Prop } from "vue-property-decorator";
import Feature from "ol/Feature";
import { QueryService } from "@/services/queryService";
import { ConfigService } from "@/services/configService";
import { MappingService } from "@/services/mappingService";
import { UtilsService } from "@/services/utilsService";
import { ISLDClass } from "../../../../interfaces/ISLDClass";
import { IVectorLayer, IRadarField } from "../../../../interfaces/IMapConfig"
import ArxBar from "../../templates/ArxBar";
import ArxPie from "../../templates/ArxPie";
import ArxLine from "../../templates/ArxLine";
import ArxRadar from "../../templates/ArxRadar";
import JsonFind from "json-find";
import IFilterAttributeValue from "@/interfaces/IFilterAttributeValue";
import { bus } from "../../../../main";
import {Fill, Stroke, Style} from 'ol/style';

import VectorLayer from "ol/layer/Vector";

export interface DataItem {
  code: number;
  engName: string;
  laoName: string;
  value: number;
}

@Component({
  components: { ArxBar, ArxPie, ArxLine, ArxRadar }
})
export default class StatsLayer extends Vue {

  private layerVisible = false;
  private provincesItems = [];
  private districtsItems = {};
  private selectedProvinceId = null;
  private tableValueUnit = "value";
  private isMobileScreen = false;

  private state: any = {
    isLoading: false,
    filterValues: [],
    firstSelectedTab: "tab-stats-filter",
    rangeValues: null,
    rangeFilterIndex: null,
    yearsValues: [],
    selectedStatsMode: "provinces",
    tableData: [],
    sldClasses: [],
    legendUrl: "",
    currentUnit: "",
    selectedStyle: "",
    selectedStatsChartType: "Bar",
    chartDataLoaded: false,
    isRadarChart: false
  };

  private radarChartOptions = {
      responsive: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              display: false,
              min: 0,
              max: 10
            },
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: false
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      legend: {
        display: false
      },
      plugins: {
          legend: {
              display: false,
              labels: {}
          },
      }
    };

  @Prop({ default: null })
  layerConfig: IVectorLayer;

  private isContentVisible = false;

  private chartOptions: any = { 
    responsive: true, 
    maintainAspectRatio: true, 
    legend: {
      display: false
    } 
  };
  private chartData: any = {
    labels: [],
    datasets: []
  }

  private created (): void {
    // Init provinces and districts items
    this.initProvincesAndDistrictsItems();

    // Set table value unit
    this.setTableValueUnit();

    // Radar stats event
    bus.$on("ArxShowRadarStats", (feature: Feature) => {
      if (this.layerConfig.statistics.radarFields) {
        this.handleRadarData(feature);
      }
    });

    bus.$on("ArxMobileProvinceClickForStats", (provinceId: number) => {
      if(UtilsService.getInstance().isMobileScreen()) {
        this.state.selectedStatsMode = "districts";
        this.selectedProvinceId = provinceId;
        this.applyFilter();
      }
    });
  }

  /**
   * Set table value unit
   */
  private setTableValueUnit (): void {
    const regExp = /\{([^)]+)\}/g;

    if (this.layerConfig.statistics.unit) {
      const matches: string[] = [...this.layerConfig.statistics.unit.match(regExp)];
      const translate: string = matches && matches.length ? matches[0].replace("{", "").replace("}", "") : "value";
      this.tableValueUnit = this.layerConfig.statistics.unit.replace(translate, this.$t(translate).toString()).replace("{", "").replace("}", "");
    } else {
      this.tableValueUnit = this.$t("translate.value").toString();
    }
  }

  /**
   * Init provinces and districts items
   */
  private initProvincesAndDistrictsItems(): void {
    const provincesConfig = ConfigService.getInstance().config.map.provincesLayer;
    const districtsConfig = ConfigService.getInstance().config.map.districtsLayer;

    const provincesFeatures: Feature[] = ConfigService.getInstance().provinces;
    const districtsFeatures: Feature[] = ConfigService.getInstance().districts;

    // 1) Provinces
    provincesFeatures.forEach((feature: Feature) => {
      this.provincesItems.push({
        id: feature.get(provincesConfig.idField),
        engName: feature.get(provincesConfig.englishNameField),
        laoName: feature.get(provincesConfig.laoNameField)
      });
    });

    if (this.provincesItems.length) {
      this.provincesItems.sort((a, b) => a.engName.localeCompare(b.engName));

      this.selectedProvinceId = ConfigService.getInstance().config.map.statsDefaultProvinceId|| this.provincesItems[0].id;
    }

    // 2) Districts
    districtsFeatures.forEach((feature: Feature) => {
      this.districtsItems[feature.get(districtsConfig.idField)] = feature.get(districtsConfig.provinceField);
    });
  }

  /**
   * Handle radar data
   */
  private handleRadarData (feature: Feature): void {
    const attributes: any = feature.getProperties();
    const fields: IRadarField[] = this.layerConfig.statistics.radarFields;
    
    // 1) Stats data
    this.chartData = {
      labels: this.layerConfig.statistics.radarFields.map((confField: IRadarField) => {
        return this.$t(confField.label)
      }),
      datasets: [{
        label: '',
        data: fields.map((confField: IRadarField) => {
          const val: any = attributes[confField.field];
          return !isNaN(val) ? Math.round(Number(val) * 100) / 100 : 0
        }),
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)'
      }]
    };

    // 2) Create data table
    this.state.tableData = fields.map((confField: IRadarField, idx: number) => {
      const val: any = attributes[confField.field];
      return {
        code: idx,
        engName: this.$t(confField.label),
        laoName: this.$t(confField.label),
        value: !isNaN(val) ? Math.round(Number(val) * 100) / 100 : 0
      }
    });

    this.state.isRadarChart = true;
    this.state.chartDataLoaded = true;

    Vue.set(this.state, "firstSelectedTab", "tab-stats-graph");
  }

  /**
   * Component mounted
   */
  private mounted(): void {
    this.isContentVisible = false;
    this.isMobileScreen = UtilsService.getInstance().isMobileScreen();

    // Is radar chart
    if (this.layerConfig.statistics && this.layerConfig.statistics.radarFields && this.layerConfig.statistics.radarFields.length) {
      this.state.isRadarChart = true;
    }

    if (!this.layerConfig.statistics.calculateFromExcelFile) {
      this.layerVisible = MappingService.getInstance().getMapLayers()[this.layerConfig.name].layer.getVisible();
    }

    Vue.set(this.state, "firstSelectedTab", this.state.isRadarChart || (this.layerConfig.filters && this.layerConfig.filters.length) || (this.layerConfig.statistics.idDistrictField)? "tab-stats-filter" : "tab-stats--legend");

    this.state.selectedStatsMode = !this.isMobileScreen && this.layerConfig.statistics.calculateByProvinces ? "provinces" : this.layerConfig.statistics.calculateByDistricts ? "districts" : "";

    //Get values from layer, if searchLayer property is defined
    if (this.layerConfig.searchLayer)
      this.queryLayer(this.layerConfig.searchLayer).then((features: any[]) => {
        this.initLayer(features);
      });
    else this.initLayer();

    // Set style
    MappingService.getInstance()
      .getLayerStyles(this.layerConfig.name)
      .then((res: { styles: string[]; defaultStyle: string }) => {
        if (res && res.styles.length) {
          this.state.selectedStyle = res.defaultStyle || res.styles[0];
          this.setLayerStyle();
        }
    });

    /*bus.$on(`arxRangeSliderValue_${this.contentId}`, (rangeInfos: any) => {
      const filterSelects = this.$el.querySelectorAll(".filterSelect");

      if (filterSelects.length > rangeInfos.filterIndex) {
        const select: HTMLSelectElement = filterSelects[
          rangeInfos.filterIndex
        ] as HTMLSelectElement;

        if (select.value !== rangeInfos.value) {
          select.value = rangeInfos.value;
          this.applyFilter(rangeInfos.filterIndex);
        }
      }
    });*/
  }

  /**
   * Set chart type
   */
  private setChartType(type: string): void {
    this.state.selectedStatsChartType = type;
  }

  /**
   * Get values from layer
   * @param layer
   * @param filters
   */
  private async queryLayer(layer: string, filters: IFilterAttributeValue[] = null): Promise<any[]> {
    try {
      const features = await QueryService.querySQLViewFeatures(layer, filters);
      return Promise.resolve(features);      
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * On btn expand / collapse click
   */
  private onExpandBtnClick(event: any): void {
    this.isContentVisible = !this.isContentVisible;

    //Dispatch resize to fix tabs default selected tab
    if (!this.isMobileScreen) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200);
    }
  }

  /**
   * Init layer
   * @param features
   */
  private initLayer(features: any[] = null): void {
    this.setLayerFilter(features);
    setTimeout(() => {
      this.applyFilter();
    }, 200);
  }

  /**
   * Set layer filers
   * @param features
   */
  private setLayerFilter(features: any[] = null): void {
    if (this.layerConfig.filters) {
      this.layerConfig.filters.forEach((filterConfig: any, i: number) => {
        const oneLayFilter: any = { ...filterConfig };

        switch (filterConfig.type) {
          case "list":
          case "year":
            oneLayFilter.values = this.getValuesFromList(filterConfig, i);

            if (filterConfig.type === "year") {
              oneLayFilter.isYearFilter = true;
              this.state.yearsValues = oneLayFilter.values;
            }
            break;

          case "layer":
          case "layer_year_range":
            oneLayFilter.values = this.getValuesFromLayer(
              filterConfig,
              features
            );
            //this.currentLayerFilterAllValues = oneLayFilter.values;

            if (filterConfig.type === "layer_year_range") {
              this.initA5Values(oneLayFilter, i);
            }

            break;
        }

        // Set labels
        //this.setFilterLabels(oneLayFilter);

        this.state.filterValues.push(oneLayFilter);
      });
    }
  }

  /**
   * init A5 values
   * @param filterIndex
   */
  private initA5Values(oneLayFilter: any, filterIndex: number) {
    oneLayFilter.isYearFilter = true;
    this.state.rangeValues = {
      value: this.getStartYearFromRange(oneLayFilter.defaultValue),
      data: [],
      range: []
    };

    this.state.yearsValues = oneLayFilter.values.map((rangeItem: any) => {
      const startYear: number = this.getStartYearFromRange(rangeItem.value);

      if (startYear > -1) {
        this.state.rangeValues.range.push({ label: rangeItem.value });
        return { label: rangeItem.value, value: Number(startYear) };
      }
    });

    this.state.rangeFilterIndex = filterIndex;
    this.state.rangeValues = this.getRangesValuesA5(oneLayFilter);
  }

  /**
   * Get values from list
   * @param filterConfig
   * @param
   */
  private getValuesFromLayer(filterConfig: any, features: any[]): any[] {
    let vals: any = [];
    const tempArr: string[] = [];

    if (features) {
      features.forEach((feat: Feature) => {
        let value: string = feat.getProperties()[filterConfig.propertyName];

        if (value) {
          value = value.toString().trim();

          if (tempArr.indexOf(value) === -1) {
            vals.push({ label: value, value: value });
            tempArr.push(value);
          }
        }
      });

      //Sort and remove duplicates
      vals = vals.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    return vals;
  }

  /**
   * Get values from list
   * @param filterConfig
   * @param filterIndex
   */
  private getValuesFromList(filterConfig: any, filterIndex: number): any[] {
    if (
      (filterConfig.min && filterConfig.max) ||
      (filterConfig.minYear && filterConfig.maxYear)
    ) {
      const min = filterConfig.min || filterConfig.minYear;
      const max = filterConfig.max || filterConfig.maxYear;
      const step = filterConfig.step || filterConfig.stepYear;
      this.state.rangeValues = this.getRangesValues(
        min,
        max,
        step,
        filterConfig.defaultValue
      );
      this.state.rangeFilterIndex = filterIndex;

      return this.getRangeValueLabel(min, max, step);
    } else if (filterConfig.values) {
      return filterConfig.values;
    }

    return [];
  }

  /**
   * Get values from list
   * @param min
   * @param max
   * @param step
   */
  private getRangeValueLabel(min: number, max: number, step: number): any[] {
    const vals: any = [];

    for (let count = min; count <= max; count += step) {
      vals.push({
        label: count,
        value: count,
        isHide: count > min && count % 5 > 0
      });
    }

    return vals;
  }

  /**
   * Get values from list
   * @param min
   * @param max
   * @param step
   * @param defaultValue
   */
  private getRangesValues(
    min: number,
    max: number,
    step: number,
    defaultValue: any
  ): any {
    let rangeVals: any = null;

    rangeVals = { value: defaultValue, data: [], range: [] };

    for (let count = min; count <= max; count += step) {
      rangeVals.data.push(count);
      rangeVals.range.push({
        label: count,
        isHide: count > min && count % 5 > 0
      });
    }

    return rangeVals;
  }

  /**
   * Get values from years range (A5)
   * @param filterConfig
   */
  private getRangesValuesA5(filterConfig: any): any {
    let rangeVals: any = null;

    rangeVals = {
      value: this.getStartYearFromRange(filterConfig.defaultValue),
      data: [],
      range: []
    };
    const modulo: number = this.state.yearsValues.length >= 10 ? 5 : 2;

    this.state.yearsValues.forEach((rangeItem: any, i: number) => {
      if (rangeItem) {
        rangeVals.data.push(rangeItem.value);
        rangeVals.range.push({
          label: rangeItem.label,
          isHide: i < this.state.yearsValues.length && i % modulo > 0
        });
      }
    });

    return rangeVals;
  }

  /**
   * Get start year from range
   */
  private getStartYearFromRange(str: string): number {
    const year: string = str && str.length > 3 ? str.substring(0, 4) : "";
    return !isNaN(Number(year)) ? Number(year) : -1;
  }
  
  /**
   * On calculate stats
   */
  private calculateStats(event: any): void {
    this.isContentVisible = !this.isContentVisible;

    //Dispatch resize to fix tabs default selected tab
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }

  /**
  * Get Translation
  */
  private getTranslation(label: string): string {
    if(label && isNaN(Number(label))) {
      let tr = label.indexOf('translate.common') === - 1 ? `translate.common.${label.toLowerCase()}` : label.toLowerCase();
      tr = tr.split("'").join("_").split("’").join("_").split("/").join("_");

      if(this.$te(tr)) {
        return this.$t(tr).toString();
      }
    }

    return label;
  }

  /**
   * Handle A5 scenario
   */
  private getA5Scenario(
    scenarioFilterIndex: number,
    oldValue: string,
    yearRange: string
  ): string {
    let scenarioVal = oldValue;
    const scenarioConfig: any = this.layerConfig.filters[scenarioFilterIndex];
    const endYear: number = this.getEndYearFromRange(yearRange);

    if (new Date().getFullYear() > endYear) {
      scenarioVal = scenarioConfig.defaultValue;
    }

    return scenarioVal;
  }

  /**
   * Set year range
   * @param filterItemIndex
   */
  private setYearsRange(filterItemIndex: number): void {
    const filterConfig = this.state.filterValues[filterItemIndex];
    const filterSelects = this.$el.querySelectorAll(".filterSelect");
    const yearSelect: HTMLSelectElement = filterSelects[
      this.state.rangeFilterIndex
    ] as HTMLSelectElement;

    let doInitTS = false;
    let min: any = null;
    let max: any = null;
    let currentVal = -1;

    //Range year (A5)
    if (filterConfig && filterConfig.type === "layer_year_range") {
      //this.handleA5RangeYears(filterConfig, filterItemIndex);
      //min = filterConfig.defaultValue;
      const selectedYearLabel: string =
        yearSelect.options[yearSelect.selectedIndex] &&
        yearSelect.options[yearSelect.selectedIndex].textContent
          ? yearSelect.options[yearSelect.selectedIndex].textContent
          : "";

      if (selectedYearLabel) {
        //} && selectedYearLabel.length === 9){
        currentVal = Number(yearSelect.value);
      }

      doInitTS = true;
    }
    //Years filer
    else if (filterConfig && filterConfig.isYearFilter) {
      currentVal = Number(yearSelect.value);
      doInitTS = true;
    }

    //Range year (A4)
    else if (
      filterConfig &&
      filterConfig.values &&
      filterConfig.values.length &&
      filterConfig.values[0].minYear &&
      filterConfig.values[0].maxYear
    ) {
      if (filterSelects && filterSelects.length > filterItemIndex) {
        const targetSelect = filterSelects[
          filterItemIndex
        ] as HTMLSelectElement;
        const selectedIndex = targetSelect.selectedIndex;

        min = filterConfig.values[selectedIndex].minYear;
        max = filterConfig.values[selectedIndex].maxYear;

        // Number year (A4)
        if (!isNaN(min)) {
          const step = filterConfig.values[selectedIndex].stepYear;
          this.state.yearsValues = this.getRangeValueLabel(
            Number(min),
            Number(max),
            step
          );
          this.state.rangeValues = this.getRangesValues(
            Number(min),
            Number(max),
            step,
            filterConfig.defaultValue
          );

          this.state.rangeValues.value = min;
          doInitTS = true;
        }
      }
    }

    /*if (doInitTS) {
      this.initTimeSlider(min, currentVal);
    }*/
  }

  /**
   * Get end year from range
   */
  private getEndYearFromRange(str: string): number {
    const year: string = str && str.length > 8 ? str.substring(5, 9) : str;
    return !isNaN(Number(year)) ? Number(year) : -1;
  }

  /**
   * Create excel file download url
  */
  private createExcelFileDownloadUrl(): string {
    const arr = this.layerConfig.geoTiffDownloadUrl.replace(".tif", "").split('/');
    let filename: string = arr[arr.length - 1];
    const filterSelects = this.$el.querySelectorAll(".filterSelect");

    if (filterSelects && filterSelects.length > 0) {
      //First check if scenarioFilterIndex exists
      filterSelects.forEach((select: any, i: number) => {
        const filterConfig = this.state.filterValues[i];

        if (filterConfig.scenarioFilterIndex) {
          const selectedIndex = (select as HTMLSelectElement).selectedIndex;
          const value: string = select.value;
          const label: string =
            select.options[selectedIndex] &&
            select.options[selectedIndex].textContent
              ? select.options[selectedIndex].textContent
              : "";
          const scenarioVal: string = this.getA5Scenario(
            filterConfig.scenarioFilterIndex,
            (filterSelects[
              filterConfig.scenarioFilterIndex
            ] as HTMLSelectElement).value,
            label
          );

          if (scenarioVal) {
            filename = filename.replace(
              `{${filterConfig.scenarioFilterIndex}}`,
              scenarioVal
            );
          }
        }
      });

      //Other filters
      filterSelects.forEach((select: any, i: number) => {
        const filterConfig = this.state.filterValues[i];
        let value: string = select.value;

        if (filterConfig.type === "layer_year_range") {
          const selectedIndex = (select as HTMLSelectElement).selectedIndex;
          value =
            select.options[selectedIndex] &&
            select.options[selectedIndex].textContent
              ? select.options[selectedIndex].textContent
              : "";
        }

        filename = filename.replace(`{${i}}`, value);
      });
    }
    const filePath = this.state.selectedStatsMode === "provinces" ? this.layerConfig.statistics.provincesFilesPath : this.layerConfig.statistics.districtsFilesPath;
    const url = `
    ${ConfigService.getInstance().config.map.downloadApiUrl}/${ConfigService.getInstance().config.map.getStatsExcelFile}`.replace("{filepath}", filePath).replace("{filename}", filename);
    return url;
  }

  /**
   * Get Layer filters
  */
  private getLayerFilters(): IFilterAttributeValue[] {
    const filters: IFilterAttributeValue[] = []; 
    const filterSelects = this.$el.querySelectorAll(".filterSelect");

    if (filterSelects && filterSelects.length > 0) {
      //First check if scenarioFilterIndex exists
      filterSelects.forEach((select: any, i: number) => {
        const filterConfig = this.state.filterValues[i];

        if (filterConfig.scenarioFilterIndex) {
          const selectedIndex = (select as HTMLSelectElement).selectedIndex;
          const value: string = select.value;
          const label: string =
            select.options[selectedIndex] &&
            select.options[selectedIndex].textContent
              ? select.options[selectedIndex].textContent
              : "";
          const scenarioVal: string = this.getA5Scenario(
            filterConfig.scenarioFilterIndex,
            (filterSelects[
              filterConfig.scenarioFilterIndex
            ] as HTMLSelectElement).value,
            label
          );

          if (scenarioVal) {
            filters.push({attribute: filterConfig.propertyName, value: scenarioVal})
          }
        }
      });

      //Other filters
      filterSelects.forEach((select: any, i: number) => {
        const filterConfig = this.state.filterValues[i];
        let value: string = select.value;

        if (filterConfig.type === "layer" || filterConfig.type === "layer_year_range") {
          const selectedIndex = (select as HTMLSelectElement).selectedIndex;
          value =
            select.options[selectedIndex] &&
            select.options[selectedIndex].textContent
              ? select.options[selectedIndex].textContent
              : "";

              filters.push({attribute: filterConfig.propertyName, value: value})
        }        
      });
    }
    
    return filters;
  }

  /**
   * Set layer style
   */
  private setLayerStyle() {
    MappingService.getInstance().setLayerParams(this.layerConfig.name, [
      { param: "STYLES", value: this.state.selectedStyle }
    ]);
    this.setLayerLegend(this.layerConfig.name, this.state.selectedStyle);
  }

  /**
   * Set layer legend
   * @param layerName
   * @param styleName
   */
  private setLayerLegend(layerName: string, styleName: string) {
    this.state.legendUrl = `${
      ConfigService.getInstance().config.map.WMSBaseURL
    }?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}&style=${styleName}`;
    this.setCurrentUnit(styleName);

    this.state.selectedStyle = styleName;
  }

  /**
   * Set current unit
   */
  private setCurrentUnit(style: string): void {
    if (this.layerConfig && this.layerConfig.units) {
      const unitObj: any = this.layerConfig.units.find(
        (item: any) => item.style === style
      );

      if (unitObj) {
        this.state.currentUnit = unitObj.unit;
      }
    }
  }

  /**
   * Handle filter change
   */
  private handleFilterChange (filterItemIndex: number) {
    //Set years
    if (filterItemIndex && this.state.filterValues[filterItemIndex]) {
      this.setYearsRange(filterItemIndex);
    }

    //const ts: TimeSlider = this.$refs.timeSlider as TimeSlider;

    const filterSelects = this.$el.querySelectorAll(".filterSelect");

    if (filterSelects && filterSelects.length > 0) {

      filterSelects.forEach((select: any, i: number) => {
        const oneFilterItemValues: any = this.state.filterValues[i];
        const selectValue: string = (select as HTMLSelectElement).value;

        if (this.layerConfig.filterType === "params") {
          const selectedValueConfig = oneFilterItemValues.values.find(
            i => i.value === selectValue
          );

          /*if (ts && oneFilterItem && oneFilterItem.min && oneFilterItem.max) {
            ts.setVal(Number(selectValue));
          }*/

          if (selectedValueConfig && selectedValueConfig.style) {
            this.setLayerLegend(this.layerConfig.name, selectedValueConfig.style);
          }
        }
      });
    }
  }

  /**
   * Get SLD classes
   * @param type
   */
  private async getSLDClasses(type: number): Promise<ISLDClass[]> {
    try {
      const workspace = this.layerConfig.name.split(":")[0];
      const url = `${ConfigService.getInstance().config.map.downloadApiUrl}/${ConfigService.getInstance().config.map.getStyleSLDFile.replace('{workspace}', workspace).replace('{stylename}', this.state.selectedStyle)}`;
      const res = await fetch(url);
      const json = await res.json();      
      const doc = JsonFind(json);
      let sldClasses: ISLDClass[] = [];

      // 1) Raster style
      if (type === 1) {
        const colorMapClasses = doc.checkKey("sld:ColorMapEntry");

        if (colorMapClasses && colorMapClasses.length) {
          sldClasses = colorMapClasses.map ((oneClass: any) => {
            return {
              label: oneClass["@label"],
              min: oneClass["@min"] ? Number(oneClass["@min"]) : undefined,
              max: oneClass["@max"] ? Number(oneClass["@max"]) : undefined,
              color: oneClass["@color"]
            }
          });
        }
      }

      // 2) Vector style
      else if (type === 2) {
        const ruleClasses = doc.checkKey("sld:Rule");

        if (ruleClasses && ruleClasses.length) {
          ruleClasses.forEach ((oneClass: any) => {
            const ruleDoc = JsonFind(oneClass);
            
            if (ruleDoc) {
              const label = ruleDoc['sld:Name']
              let min: number | undefined;
              let max: number | undefined;
              let color = "A0A0A0";
              const propertyIsLessThanOrEqualTo: any = ruleDoc.checkKey("ogc:PropertyIsLessThanOrEqualTo");
              const propertyIsLessThan: any = ruleDoc.checkKey("ogc:PropertyIsLessThan");
              const propertyIsGreaterThanOrEqualTo: any = ruleDoc.checkKey("ogc:PropertyIsGreaterThanOrEqualTo");
              const propertyIsGreaterThan: any = ruleDoc.checkKey("ogc:PropertyIsGreaterThan");
              const svgParameter: any = ruleDoc.checkKey("sld:CssParameter");
              
              if (propertyIsLessThanOrEqualTo) {
                max = Number(propertyIsLessThanOrEqualTo['ogc:Literal']);
              }
              else if (propertyIsLessThan) {
                max = Number(propertyIsLessThan['ogc:Literal']);
              }
              
              if (propertyIsGreaterThanOrEqualTo) {
                min = Number(propertyIsGreaterThanOrEqualTo['ogc:Literal']);
              }
              else if (propertyIsGreaterThan) {
                min = Number(propertyIsGreaterThan['ogc:Literal']);
              }

              if (svgParameter) {
                color = svgParameter['#text']
              }

              sldClasses.push(
                {
                label: label,
                min: min,
                max: max,
                color: color
              });
            }
          });
        }
      }

      return Promise.resolve(sldClasses);
    }
    catch(err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  /**
   * Set stats data
  */ 
  private setStatsData(): void {
    if (this.state.sldClasses) {
      const labels: string[] = [];
      const datasets =  [{
        label: [],
        backgroundColor: [],
        data: []
      }];

      const tableData = this.state.selectedStatsMode === "provinces" ? this.state.tableData : this.state.tableData.filter ( (item: DataItem) => Number(this.districtsItems[item.code]) === Number(this.selectedProvinceId));

      tableData.forEach( (item: DataItem, idx: number) => {
        labels.push(item.engName);
        const sld: ISLDClass = this.getValueSLDClass(item.value);
        datasets[0].backgroundColor.push(sld && sld.color ? sld.color : "#A0A0A0");
        datasets[0].data.push(item.value);
      });

      this.chartData.labels = labels;
      this.chartData.datasets = datasets;
    } else {
      this.chartData.labels = [];
      this.chartData.datasets = [];
    }
  }

  /**
   * Get corresponding sld class for a value
   */
  private getValueSLDClass(value: number): ISLDClass {
    let sldClass: ISLDClass;

    this.state.sldClasses.forEach( (sld: ISLDClass, i: number) => {
      const min: number = sld.min !== undefined ? sld.min : undefined;
      const max: number = sld.max !== undefined ? sld.max : undefined;
      let valueOK = false;

      valueOK = min !== undefined && max !== undefined && value >= min && value <= max ? true : valueOK;
      valueOK = min !== undefined && max === undefined && value >= min ? true : valueOK;
      valueOK = min === undefined && max !== undefined && value <= max ? true : valueOK;
      
      if (valueOK) {
        sldClass = sld;
      }
    });

    return sldClass;
  }

  /**
   * Set provinces and districts layers source
   */
  private setProvincesOrDistrictsFeatures(features: any[]): void {
    const targetLayer: VectorLayer = this.state.selectedStatsMode === "provinces" ? MappingService.getInstance().getMapLayers().provincesStats.layer : MappingService.getInstance().getMapLayers().districtsStats.layer;
    const otherLayer: VectorLayer = this.state.selectedStatsMode === "provinces" ? MappingService.getInstance().getMapLayers().districtsStats.layer : MappingService.getInstance().getMapLayers().provincesStats.layer;

    targetLayer.getSource().clear();
    otherLayer.getSource().clear();

    targetLayer.setVisible(this.layerVisible);
    otherLayer.setVisible(false);

    targetLayer.getSource().addFeatures(features);
  }
  
  /**
   * Set layer visibility
   */
  private setLayerVisibility(): void {
    this.layerVisible = !this.layerVisible;

    // 1) Calculate from Excel file
    if (this.layerConfig.statistics.calculateFromExcelFile) {
      if (this.state.selectedStatsMode === "provinces") {
        MappingService.getInstance().getMapLayers().provincesStats.layer.setVisible(this.layerVisible);
        MappingService.getInstance().getMapLayers().districtsStats.layer.setVisible(false);
        MappingService.getInstance().getMapLayers().districtsStats.layer.getSource().clear();
      } else {
        MappingService.getInstance().getMapLayers().provincesStats.layer.setVisible(false);
        MappingService.getInstance().getMapLayers().districtsStats.layer.setVisible(this.layerVisible);
        MappingService.getInstance().getMapLayers().provincesStats.layer.getSource().clear();     
      }

      if (this.layerVisible) {
        this.applyFilter();
      }

      MappingService.getInstance().getMapLayers().provincesStats.layer.getSource().refresh();
      MappingService.getInstance().getMapLayers().districtsStats.layer.getSource().refresh();
    } else {
      MappingService.getInstance().setLayerVisibility(
      this.layerConfig.name,
      this.layerVisible
      );
    }
  }

  /**
   * Get the SLD File of an item
   */
  private getSLDStyle(value: number): Style {
    let color = '808080';

    this.state.sldClasses.forEach( (sld: ISLDClass, i: number) => {
      const min: number = sld.min !== undefined ? sld.min : undefined;
      const max: number = sld.max !== undefined ? sld.max : undefined;
      let valueOK = false;

      valueOK = min !== undefined && max !== undefined && value >= min && value <= max ? true : valueOK;
      valueOK = min !== undefined && max === undefined && value >= min ? true : valueOK;
      valueOK = min === undefined && max !== undefined && value <= max ? true : valueOK;
      
      if (valueOK) {      
        color = sld.color;
      }
    });

    return new Style({
        fill: new Fill({
          color: color || "#C0C0C0"
        }),
        stroke: new Stroke({
          color: "#000000",
          width: 0.5
        })
    });
  }
  /**
   * Handle data
   */
  private async handleData() {
    try {
      this.state.chartDataLoaded = false;
      this.state.isRadarChart = false;
      const config = this.state.selectedStatsMode === "provinces" ? ConfigService.getInstance().config.map.provincesLayer : ConfigService.getInstance().config.map.districtsLayer;
      const idProvOrDistrictField = this.state.selectedStatsMode === "districts" ? this.layerConfig.statistics.idDistrictField : this.layerConfig.statistics.idProvinceField;
      const valueField = this.layerConfig.statistics.valueField;
      const features: Feature[] = this.state.selectedStatsMode === "provinces" ? ConfigService.getInstance().provinces : ConfigService.getInstance().districts;
      this.state.isLoading = true;
      let statsData: any = null;

      // 1) Calculate from Excel file
      if (this.layerConfig.statistics.calculateFromExcelFile) {
        this.state.sldClasses = await(this.getSLDClasses(1));
        const excelFileDownloadUrl = this.createExcelFileDownloadUrl();

        const res = await fetch(excelFileDownloadUrl);
        statsData = await res.json();

        if (!statsData || statsData.status === 500) {
          throw new Error('Error laoding excel file');
        }
      }

      // 2) Calculate from table
      else if (!this.layerConfig.statistics.radarFields) {
        this.state.sldClasses = await(this.getSLDClasses(2));
        const filters: IFilterAttributeValue[] = this.getLayerFilters();
        const layerFeatures: Feature[] =  await this.queryLayer(this.layerConfig.statistics.statsLayer || this.layerConfig.name, filters);

        if (layerFeatures) {
          statsData = layerFeatures.map((feat: Feature) => {
            const outObj = {};
            outObj[idProvOrDistrictField] = feat.get(idProvOrDistrictField);
            outObj[valueField] = feat.get(valueField);
            return outObj;
          });
        }
      }

      // 3) Create data table
      if (statsData && statsData.length && features && features.length) {
        const outFeatures = [];

        this.state.tableData = statsData.map( (item: {code: number, value: number}) => {
          const feature: Feature = features.find ( (feat: Feature) => feat.get(config.idField) === item[idProvOrDistrictField]);

          if (feature) {
            feature.setStyle(this.getSLDStyle(item.value));
            outFeatures.push(feature);
          }

          return {
            code: item[idProvOrDistrictField],
            engName: feature ? feature.get(config.englishNameField) : "",
            laoName: feature ? feature.get(config.laoNameField) : "",
            value: Math.round(Number(item[valueField]) * 100) / 100
          }
        }).sort( (a, b) => a.code - b.code);

        this.state.tableData = this.state.selectedStatsMode === "provinces" ? this.state.tableData : this.state.tableData.filter ( (item: DataItem) => Number(this.districtsItems[item.code]) === Number(this.selectedProvinceId));

        // Set provinces and districts layers
        if (this.layerConfig.statistics.calculateFromExcelFile) {
          this.setProvincesOrDistrictsFeatures(features);
        }
      }

      // 4) Create stats data
      this.setStatsData();
      
      this.state.isLoading = false;
      this.state.chartDataLoaded = true;
    }
    catch(err) {
      console.log(err);
      this.state.isLoading = false;
      this.state.chartDataLoaded = false;
      this.state.tableData = [];
      this.chartData.labels = [];
      this.chartData.datasets = [];
      MappingService.getInstance().getMapLayers().provincesStats.layer.getSource().clear();
      MappingService.getInstance().getMapLayers().districtsStats.layer.getSource().clear();
    }
  }

  /**
   * Apply filter
   */
  private handleViewParams() {
    const filterSelects = this.$el.querySelectorAll(".filterSelect");

    if (filterSelects && filterSelects.length > 0) {
      const viewparamsStr: string[] = [];

      filterSelects.forEach((select: any, i: number) => {
        const oneFilterItem: any = this.layerConfig.filters[i];
        const oneFilterItemValues: any = this.state.filterValues[i];
        const selectValue: string = (select as HTMLSelectElement).value;
        const selectedIndex: number = (select as HTMLSelectElement)
          .selectedIndex;
        const selectLabel: string =
          select.options[selectedIndex] &&
          select.options[selectedIndex].textContent
            ? select.options[selectedIndex].textContent
            : "";

        if (this.layerConfig.filterType === "viewparams") {
          viewparamsStr.push(
            `${oneFilterItemValues.propertyName}:${selectValue}`
          );
        }         
      });

      if (this.layerConfig.filterType === "viewparams") {
        MappingService.getInstance().setLayerViewparams(
          this.layerConfig.name,
          viewparamsStr.join(";")
        );
      }
    }
  }

  /**
   * Apply filter
   * @param filterItemIndex
   */
  public applyFilter(filterItemIndex: number = null) {
    this.handleFilterChange(filterItemIndex);
    this.handleData();
    this.handleViewParams();
  }
}
