








import { Component, Vue, Watch } from "vue-property-decorator";
import ArxMap from "@/components/ArxMap/ArxMap.vue";
import DownloadLayer from "@/components/DownloadLayer/DownloadLayer.vue";
import Search from "@/widgets/Search/Search.vue";
import { Route } from "vue-router";

@Component({
  components: {
    ArxMap,
    Search,
    DownloadLayer
  }
})
export default class Home extends Vue {
  private mapKey = 0;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newRoute: Route) {
    if (newRoute.name === "MapWithParam") {
      this.mapKey = new Date().getTime();
      //this.$refs.arxMap.reinitializeMapLayers();
    }
  }
}
