



import { Component, Vue, Watch } from "vue-property-decorator";
import Layers from "@/widgets/Layers/Layers.vue";
import MapComparison from "@/widgets/MapComparison/MapComparison.vue";
import Statistics from "@/widgets/Statistics/Statistics.vue";
import { Route } from "vue-router";
import { bus } from "../../main";
import { UtilsService } from "@/services/utilsService";

@Component({
  components: {
    Layers,
    MapComparison,
    Statistics
  }
})
export default class ArxSideBar extends Vue {
  private LAYERS = "layers";
  private MAP_COMPARISON = "mapComparison";
  private STATISTICS = "statistics";
  private isMobileScreen = false;

  private state: any = {
    currentTool: ""
  };

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newRoute: Route) {
    if (newRoute.name && !newRoute.name.startsWith("Map")) {
      this.onCloseSideBar();
    }
  }

  /**
   * Mounted
   */
  private mounted(): void {
    this.isMobileScreen = UtilsService.getInstance().isMobileScreen();
    
    bus.$on("showTool", (tool: string) => {
      if (this.isMobileScreen && tool === this.STATISTICS) {
        document.body.classList.add('statsForMobile');
      } else {
        document.body.classList.remove('statsForMobile');
      }
      Vue.set(this.state, "currentTool", tool);
      this.showSideBar();
    });
  }

  /**
   * Show side bar
   */
  showSideBar(): void {
    const elem: HTMLElement = document.getElementById(
      "arxSideBar"
    ) as HTMLElement;
    elem.classList.remove("hidden");
    elem.classList.add("visible");
  }

  /**
   * Handle close sidebar
   */
  public onCloseSideBar(): void {
    document.body.classList.remove('statsForMobile');
    
    //Handle map comparison
    if (this.state.currentTool === this.MAP_COMPARISON)
      (this.$refs["mapComparisonWidget"] as MapComparison).handleSideBarClose();

    const elem: HTMLElement = document.getElementById(
      "arxSideBar"
    ) as HTMLElement;

    if (elem && elem.classList) {
      elem.classList.remove("visible");
      elem.classList.add("hidden");
    }
    this.state.currentTool = "";
    bus.$emit("unselectTools");
  }
}
