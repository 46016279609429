import { IThematicConfig } from "@/interfaces/IThematicConfig";
import { IConfig } from "../interfaces/IConfig";
import { IMetadata } from "../interfaces/IMetadata";
import { QueryService } from "./queryService";
import Feature from "ol/Feature";
import { IDataset, IVectorLayer } from "@/interfaces/IMapConfig";
import { UtilsService } from "./utilsService";

export class ConfigService {
  private static instance: ConfigService;
  public config: IConfig;
  public metadata: {en: IMetadata[], lo: IMetadata[]};
  public defaultThematic: string;
  public provinces: Feature[] = [];
  public districts: Feature[] = [];
  
  /**
   * Filter dataset layers for mobile
   * @returns
   */
  private static filterDatasetLayerForMobile(dataset: IDataset): IDataset | null {
    if (dataset && dataset.layers) {
      dataset.layers = dataset.layers.filter ( (layer: any | IVectorLayer) => layer.enabledOnMobile);
      return dataset.layers.length ? dataset : null;
    }

    return null;
  }

  /**
   * Get config filtered on thematic
   * @param config
   * @param thematic
   * @returns
   */
  private getFilterdConfigOnThematic(
    config: IConfig,
    thematic: string
  ): IConfig {
    const filteredConfig: IConfig = JSON.parse(JSON.stringify(config));
    const isMobileScreen: boolean = UtilsService.getInstance().isMobileScreen();

    if (config.thematics && config.thematics.length) {
      //1) If thematic, check if it exists in the config
      let thematicExists = false;
      if (thematic) {
        config.thematics.forEach((oneConfThematic: IThematicConfig) => {
          if (thematic === oneConfThematic.id) {
            thematicExists = true;
            return false;
          }
        });
      }

      //2) If no thematic, get default thematic from config
      else if (!thematicExists || !thematic) {
        config.thematics.forEach((oneConfThematic: IThematicConfig) => {
          if (oneConfThematic.isDefault) {
            thematic = oneConfThematic.id;
            return false;
          }
        });
      }

      //If no default thematic, take the first
      if (!thematicExists || !thematic) {
        thematic = config.thematics[0].id;
      }

      this.defaultThematic = thematic;

      //Filter config on thematic

      filteredConfig.map.datasets = {};

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A1);

        if (ds) {
          filteredConfig.map.datasets.A1 = ds;
        }
      }
      else if (config.map.datasets.A1.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A1 = config.map.datasets.A1;
      }

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A2);

        if (ds) {
          filteredConfig.map.datasets.A2 = ds;
        }
      }
      else if (config.map.datasets.A2.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A2 = config.map.datasets.A2;
      }

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A3);

        if (ds) {
          filteredConfig.map.datasets.A3 = ds;
        }
      }
      else if (config.map.datasets.A3.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A3 = config.map.datasets.A3;
      }

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A4);

        if (ds) {
          filteredConfig.map.datasets.A4 = ds;
        }
      }
      else if (config.map.datasets.A4.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A4 = config.map.datasets.A4;
      }

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A5);

        if (ds) {
          filteredConfig.map.datasets.A5 = ds;
        }
      }
      else if (config.map.datasets.A5.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A5 = config.map.datasets.A5;
      }

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A6);

        if (ds) {
          filteredConfig.map.datasets.A6 = ds;
        }
      }
      else if (config.map.datasets.A6.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A6 = config.map.datasets.A6;
      } 

      if (isMobileScreen) {
        const ds: IDataset = ConfigService.filterDatasetLayerForMobile(config.map.datasets.A7);

        if (ds) {
          filteredConfig.map.datasets.A7 = ds;
        }
      }
      else if (config.map.datasets.A7.thematics.indexOf(thematic) > -1) {
        filteredConfig.map.datasets.A7 = config.map.datasets.A7;
      }
    }

    return filteredConfig;
  }

  /**
   * Load config
   * @param thematic
   * @returns
   */
  public async loadConfig(thematic: string): Promise<IConfig> {
    return new Promise<IConfig>((resolve, reject) => {
      fetch(`./config/config.json?date=${new Date().getTime()}`)
        .then(res => res.json())
        .then(
          conf => {
            this.config = this.getFilterdConfigOnThematic(conf, thematic);
            ConfigService.loadMetadata()
              .then((metadata: {en: IMetadata[], lo: IMetadata[]}) => {
                this.metadata = metadata;
                resolve(this.config);
              })
              .catch((err: any) => {
                resolve(this.config);
              });
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  /**
   * Load metadata
   * @returns
   */
  public static async loadMetadata(): Promise<{en: IMetadata[], lo: IMetadata[]}> {
    if (ConfigService.getInstance().metadata) {
      return ConfigService.getInstance().metadata;
    }

    const res: {en: IMetadata[], lo: IMetadata[]} = {en: [], lo: []};

    return new Promise<any>((resolve, reject) => {
      QueryService.queryFeatures(
        QueryService.createFeatureRequest(["laos_A1:metadata"])
      ).then((features: any[]) => {
        if (features) {
          features.forEach((feat: Feature) => {
            const metadata: IMetadata = feat.getProperties() as IMetadata;
            const lang: string = feat.getProperties().lang;

            if (lang === 'en') {
              res.en.push(metadata);
            } else if (lang === 'lo') {
              res.lo.push(metadata);
            }
          });
  
          resolve(res);
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * Get config service instance
   */
  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }
}
