



import { Component, Vue } from "vue-property-decorator";
import ExpandableCard from "./templates/ExpandableCard/ExpandableCard.vue";
import MapLayer from "@/components/ArxMap/MapLayer/MapLayer.vue";
import { IDataset } from "../../interfaces/IMapConfig";
import { Helper } from "./helper";
import { UtilsService } from "@/services/utilsService";

@Component({
  components: {
    ExpandableCard,
    MapLayer
  }
})
export default class Dataset extends Vue {
  private helper: Helper = new Helper();
  private datasets: IDataset[] = [];
  private isMobileScreen = false;

  private mounted(): void {
    this.isMobileScreen = UtilsService.getInstance().isMobileScreen();
    this.datasets.push(this.helper.getA1Dataset());
    this.datasets.push(this.helper.getA2Dataset());
    this.datasets.push(this.helper.getA3Dataset());
    this.datasets.push(this.helper.getA4Dataset());
    this.datasets.push(this.helper.getA5Dataset());
    this.datasets.push(this.helper.getA6Dataset());
    this.datasets.push(this.helper.getA7Dataset());
    this.datasets.push(this.helper.getA8Dataset());
  }
}
