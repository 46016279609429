import { MappingService } from "../../services/mappingService";

export class Helper {
  /**
   * Initialize Map
   */
  public initializeMap() {
    MappingService.getInstance().initMap();
  }
}
