import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Map from "../views/Map.vue";
import Home from "../views/Home.vue";
import ThematicPage from "../views/ThematicPage/ThematicPage.vue";
import Credits from "../views/Credits/Credits.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/?thematic=:thematic",
    name: "MapWithParam",
    component: Map
  },
  {
    path: "/?thematic=:thematic&lat=:lat&lng=:lng",
    name: "MapWithLatLngParam",
    component: Map
  },
  {
    path: "/?thematic=:thematic",
    name: "ThematicWithParam",
    component: ThematicPage
  },
  {
    path: "/credits",
    name: "CreditsWithParam",
    component: Credits
  },
  {
    path: "/help",
    name: "HelpWithParam",
    beforeEnter() {
      window.open("./config/Help.pdf", "_blank");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
