










































import { Component, Vue } from "vue-property-decorator";
import ThematicItem from "@/components/ThematicItem/ThematicItem.vue";
import { ConfigService } from "@/services/configService";
import { UtilsService } from "@/services/utilsService";
import { IThematicConfig } from "@/interfaces/IThematicConfig";

@Component({
  components: {
    ThematicItem
  }
})
export default class Home extends Vue {
  private thematics: IThematicConfig[] = [];

  private mounted(): void {
    this.thematics = ConfigService.getInstance().config.thematics;

    if (UtilsService.getInstance().isMobileScreen()) {
      setTimeout(() => {
        this.$router.push({
          name: "MapWithLatLngParam",
          params: { 
            thematic: ConfigService.getInstance().config.thematics[0].id, 
            lat:  `${UtilsService.getInstance().lat}`, 
            lng: `${UtilsService.getInstance().lng}`
          }
        });
      }, 500);
    }
  }
}
