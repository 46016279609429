



import { Component, Vue, Prop } from "vue-property-decorator";
import { ConfigService } from "@/services/configService";
import { IThematicConfig } from "../../interfaces/IThematicConfig";

@Component
export default class ThematicItem extends Vue {
  private iconClass = "";

  @Prop({ default: "" })
  index: string;

  @Prop({ default: "" })
  thematicItem: IThematicConfig;

  private mounted(): void {
    this.iconClass = `iconPosition_${this.index}`;
  }

  /**
   * Get icon
   */
  private getIcon(): any {
    return require(`@/assets/thematics/${this.$i18n.locale}/${this.thematicItem.id.toLowerCase()}/${
      this.thematicItem.icon
    }`);
  }

  /**
   * Show thematic
   */
  private async showThematic(): Promise<void> {
    try {
      await ConfigService.getInstance().loadConfig(this.thematicItem.id);

      if (this.thematicItem.isMap) {
        this.$router.push({
          name: "MapWithParam",
          params: { thematic: this.thematicItem.id }
        });
      } else {
        this.$router.push({
          name: "ThematicWithParam",
          params: { thematic: this.thematicItem.id }
        });
      }
    } catch (err) {
      console.error("Unable to load configuration from config.json !");
    }
  }
}
