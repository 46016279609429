import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


// Vue material
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import i18n from "./i18n";
Vue.use(VueMaterial);

//Event bus
export const bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
