



import { Component, Vue, Prop } from "vue-property-decorator";
import { ConfigService } from "@/services/configService";
import { bus } from "../../main";

@Component
export default class ThematicItem extends Vue {
  private visibilityClass = "";
  private downloadUrl = "";
  private firstName = "";
  private lastName = "";
  private email = "";
  private purpose = "";
  private isError = false;
  private isLoading = false;
  private layerName = "";

  @Prop({ default: "" })
  url: string;

  private created(): void {
    bus.$on("openDownloadLayer", (download: { name: string; url: string }) => {
      this.visibilityClass = "visible";
      this.downloadUrl = download.url;
      this.layerName = download.name;
      this.purpose = "";
    });
  }

  private onClose(): void {
    this.visibilityClass = "";
  }

  private validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  private async onDownload(): Promise<void> {
    try {
      this.isLoading = true;

      const response = await fetch(
        ConfigService.getInstance().config.map.downloadApiUrl,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json"
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            firstname: this.firstName,
            lastname: this.lastName,
            email: this.email,
            purpose: this.purpose
          })
        }
      );

      this.isLoading = false;

      if (response.ok) {
        this.visibilityClass = "false";
        window.open(this.downloadUrl);
      } else {
        this.isError = true;

        setTimeout(() => {
          this.isError = false;
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      this.isLoading = false;
    }
  }
}
