import { Component, Mixins, Prop } from "vue-property-decorator";
import { Pie, mixins } from "vue-chartjs";

@Component({
  extends: Pie,
  mixins: [mixins.reactiveProp]
})
export default class ArxPie extends Mixins(mixins.reactiveProp, Pie) {
  @Prop({ default: null })
  chartData: any;

  @Prop({ default: null })
  chartOptions: any;

  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }
}
