import "ol/ol.css";
import * as olControl from "ol/control";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import TileWMS from "ol/source/TileWMS";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import Feature from "ol/Feature";
import { getCenter } from "ol/extent";
import { boundingExtent } from 'ol/extent';
import { getRenderPixel } from "ol/render";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import "ol/ol.css";
import { IMapConfig, IStatistics, IVectorLayer } from "../interfaces/IMapConfig";
import { ConfigService } from "./configService";
import { PopupService } from "./popupService";
import { QueryService } from "./queryService";
import { UtilsService } from "./utilsService";
import { bus } from "../main";

//import Tile from 'ol/Tile';

export class MappingService {
  private static instance: MappingService;
  private mapConfig: IMapConfig;
  private map: Map;
  private view: View;
  private mapLayers: any = {};
  private highlightLayer: VectorLayer;

  public static swipeLayerName = "swipeLayer";
  public swipeValue: number;

  /**
   * Check
   */
  private checkIsMobileDevice(): boolean {
    return innerWidth <= 600;
  }

  private static createRelatedContent(relatedFeatures: Feature[], config:any): string {
    let content = "";
    relatedFeatures.forEach( (feat: Feature, i: number) => {
      if (content) {
        content += `</br>`;
      }
      content += `_______ <span class="itemNum">${i + 1}</span> _______`;
      content += `</br>`;

      const props = feat.getProperties();
      
      config.fields.forEach( (field: {label: string, propertyName: string}, i: number) => {
        content += `<strong>${field.label}</strong>: ${props[field.propertyName]}`;
        content += i < config.fields.length - 1 ? `</br>` : '';
      });
    });

    return `<div class="popupRelatedContent">${content}</div>`;
  }

  /**
   * Init view
   */
  private initView(): void {
    const isMobileDevice: boolean = this.checkIsMobileDevice();

    this.view = new View({
      zoom: isMobileDevice ? this.mapConfig.zoom -1 : this.mapConfig.zoom,
      maxZoom: this.mapConfig.maxZoom,
      minZoom: this.mapConfig.minZoom,
      center: this.mapConfig.center,
      constrainResolution: this.mapConfig.constrainResolution,
      projection: this.mapConfig.projection
    });

    /*this.view.on("change:center", () => {
      this.setCanvasTransform();
    });*/
  }

  /**
   * Init Highlight layer
   */
  private initHighlightLayer(): void {
    const highlightStyle = new Style({
      /*fill: new Fill({
        color: 'rgba(255,255,255,0.7)',
      }),*/
      stroke: new Stroke({
        color: "#3399CC",
        width: 3
      })
    });

    this.highlightLayer = new VectorLayer({
      source: new VectorSource(),
      style: highlightStyle,
      map: this.map
    });
  }

  /**
   * Get basemap layers
   */
  private getBasemapLayers(): TileLayer[] {
    return [
      new TileLayer({
        source: new OSM()
      })
    ];
  }

  /**
   * Get layers
   */
  private getLayers(): TileLayer[] {
    return this.getA1Layers()
      .reverse()
      .concat(this.getA2Layers().reverse())
      .concat(this.getA3Layers().reverse())
      .concat(this.getA4Layers().reverse())
      .concat(this.getA5Layers().reverse())
      .concat(this.getA6Layers().reverse())
      .concat(this.getA7Layers().reverse())
      .concat(this.getA8Layers().reverse());
  }

  /**
   * Create vector layer
   * @param layerConfig
   * @returns
   */
  private createVectorLayer(layerConfig: IVectorLayer): TileLayer {
    const params: any = {
      LAYERS: layerConfig.name,
      TILED: layerConfig.tiled
    };

    //When viewparams
    if (layerConfig.viewparams) {
      params.viewparams = layerConfig.viewparams;
    }

    const source: TileWMS = new TileWMS({
      url: ConfigService.getInstance().config.map.WMSBaseURL,
      params: params,
      serverType: "geoserver",
      transition: 0
    });

    const layer = new TileLayer({
      source: source
    });

    layer.setVisible(layerConfig.visible);
    layer.setOpacity(layerConfig.opacity);

    //this.makeWorkaroundCanvasTransform(source);
    return layer;
  }

  /**
   * Get A1 layers
   */
  private getA1Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A1) {
      ConfigService.getInstance().config.map.datasets.A1.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A2 layers
   */
  private getA2Layers(): TileLayer[] {
    const layers: TileLayer[] = [];
    if (ConfigService.getInstance().config.map.datasets.A2) {
      ConfigService.getInstance().config.map.datasets.A2.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A3 layers
   */
  private getA3Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A3) {
      ConfigService.getInstance().config.map.datasets.A3.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A4 layers
   */
  private getA4Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A4) {
      ConfigService.getInstance().config.map.datasets.A4.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A5 layers
   */
  private getA5Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A5) {
      ConfigService.getInstance().config.map.datasets.A5.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A6 layers
   */
  private getA6Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A6) {
      ConfigService.getInstance().config.map.datasets.A6.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A7 layers
   */
  private getA7Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A7) {
      ConfigService.getInstance().config.map.datasets.A7.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A8 layers
   */
  private getA8Layers(): TileLayer[] {
    const layers: TileLayer[] = [];

    if (ConfigService.getInstance().config.map.datasets.A8) {
      ConfigService.getInstance().config.map.datasets.A8.layers.forEach(
        (layerConfig: IVectorLayer) => {
          const layer = this.createVectorLayer(layerConfig);
          layers.push(layer);
          this.mapLayers[layerConfig.name] = {
            layer: layer,
            config: layerConfig
          };
        }
      );
    }

    return layers;
  }

  /**
   * Get A1 layers
   */
   private intStatsLayers(): VectorLayer[] {
    const provincesLayer : VectorLayer = new VectorLayer({
      source: new VectorSource(),
      map: this.map,
      visible: false
    });

    this.mapLayers.provincesStats = {
      layer: provincesLayer,
      config: ConfigService.getInstance().config.map.provincesLayer,
      map: this.map
    };

    const districtsLayer: VectorLayer = new VectorLayer({
      source: new VectorSource(),
      map: this.map,
      visible: false
    });

    this.mapLayers.districtsStats = {
      layer: districtsLayer,
      config: ConfigService.getInstance().config.map.provincesLayer,
      map: this.map
    };

    return [provincesLayer, districtsLayer];
  }

  /**
   * Handle map click event
   
  private setCanvasTransform(): void {
    const mapContainer = document.getElementById("mapContainer");

    if (mapContainer) {
      const canvasList = mapContainer.querySelectorAll("canvas");

      if (canvasList && canvasList.length > 0) {
        canvasList.forEach(canvas => {
          canvas.style.transform = "inherit";
        });
      }
    }
  }*/

  /**
   * Make work around canvas transform
   * @param tileWMS
   
  private makeWorkaroundCanvasTransform(tileWMS: TileWMS): void {
    tileWMS.on("tileloadend", () => {
      this.setCanvasTransform();
    });
    
    tileWMS.on("tileloadstart", () => {
      this.setCanvasTransform();
    });

    window.onresize = () => {
      setTimeout(() => {
        this.setCanvasTransform();
      }, 200);
    };
  }*/

  /**
   * Handle Map events
   */
  private handleMapEvents(): void {
    this.map.on("pointermove", e => {
      bus.$emit(
        "ArxMapCoords",
        `${e.coordinate[0].toFixed(2)}, ${e.coordinate[1].toFixed(2)}`
      );
    });

    this.map.on("singleclick", evt => {
      PopupService.getInstance().closePopup();
      this.handlePopup(evt);
    });
  }

  /**
   * Handle popup
   * @param evt
   */
  private handlePopup(evt: any): void {
    const coordinates = evt.coordinate;
    const pixel = this.map.getEventPixel(evt.originalEvent);
    let popupHandled = false;
    PopupService.getInstance().reinitializeState();

    bus.$emit("ArxHidePopup");

    //Remove existing highlight
    this.clearHighlights();

    this.map.forEachLayerAtPixel(pixel, e => {
      const layer: TileLayer = e as TileLayer;

      if (layer && layer.getSource() && layer.getSource() instanceof TileWMS) {
        const source: TileWMS = layer.getSource() as TileWMS;
        const params = source.getParams();

        if (params && params.LAYERS && this.mapLayers[params.LAYERS]) {
          const mapLayer = this.mapLayers[params.LAYERS];

          if (mapLayer && mapLayer.config) {
            const mapLayerConfig: IVectorLayer = mapLayer.config;
            const isMobileProvinceClick = UtilsService.getInstance().isMobileScreen() && mapLayerConfig.name === ConfigService.getInstance().config.map.provincesLayer.name;

            if (mapLayerConfig.popup) {
              PopupService.getInstance().currentPopupFeatures.push({
                source: source,
                config: mapLayer.config.popup,
                statsConfig: mapLayer.config.statistics,
                coordinates: coordinates
              });

              if (!popupHandled) {
                popupHandled = true;

                this.queryFeaturesForPopup(
                  source,
                  mapLayer.config.popup,
                  coordinates,
                  mapLayer.config.statistics,
                  isMobileProvinceClick
                );
              }
            }
          }
        }
      }
    });
  }

  /**
   * Show popup
   * @param feature
   * @param config
   * @param coordinates
   * @param relatedContent
   */
  private showPopup(feature: Feature, config: any, coordinates: any, relatedContent = ""): void {
    PopupService.getInstance().openPopup(
      feature.getProperties(),
      config,
      coordinates,
      relatedContent
    );
  }

  /**
   * Remove swipe layer
   */
  private removeSwipeLayer(): void {
    if (
      this.mapLayers[MappingService.swipeLayerName] &&
      this.mapLayers[MappingService.swipeLayerName].layer
    ) {
      this.map.removeLayer(this.mapLayers[MappingService.swipeLayerName].layer);
      this.mapLayers[MappingService.swipeLayerName] = null;
    }
  }

  /**
   * Handle popup coming from search
   * @param feature
   */
  private handlePopupFromSearch(feature: Feature): void {
    const layerName: string = feature
      .getId()
      .toString()
      .split(".")[0];

    //Get popup config
    let popupConfig: any = null;

    for (const key in this.mapLayers) {
      if (key === layerName || key.endsWith(`:${layerName}`)) {
        popupConfig = this.mapLayers[key].config.popup;
        break;
      }
    }

    if (popupConfig) {
      const extent = feature.getGeometry().getExtent();
      const center = getCenter(extent);
      this.showPopup(feature, popupConfig, center);
    }

    //Highlight feature
    this.highlightLayer.getSource().addFeature(feature);
  }

  /*mouseMoveFunction = (e) => {
    setTimeout(() => {
      this.setCanvasTransform();
    }, 100); 
  }*/

  /**
   * Handle radar stats
   * @param feature
   * @param statsConfig
   */
  private handleRadarStats(feature: any, statsConfig: IStatistics): void {
    if (statsConfig && statsConfig.radarFields && statsConfig.radarFields.length) {
      bus.$emit(
        "ArxShowRadarStats",
        feature
      );
    }
  }

  /**
   * Init the map
   */
  public initMap(): void {
    this.map = null;

    //Init popup
    PopupService.getInstance().initPopup();

    //Init view
    this.initView();

    this.map = new Map({
      target: "mapContainer",
      controls: olControl.defaults({
        attribution: true,
        zoom: false
      }),
      layers: [],
      view: this.view,
      overlays: [PopupService.getInstance().popupElements.overlay]
    });

    this.initMapLayers();

    //Handle map events
    this.handleMapEvents();

    //Init highlight layer
    this.initHighlightLayer();

    if (UtilsService.getInstance().isMobileScreen()) {
      this.map.once('postrender', (event: any) => {
        this.zoomToPosition();
      });
    }
  }

  /**
   * Zoom to a position
   */
  private zoomToPosition() {
    const provincesLayer: TileLayer = MappingService.getInstance().getLayerByName(ConfigService.getInstance().config.map.provincesLayer.name);
    
    if (provincesLayer && provincesLayer.getSource() && provincesLayer.getSource() instanceof TileWMS) {
      const source: TileWMS = provincesLayer.getSource() as TileWMS;

      if (source) {
        MappingService.getInstance().queryFeatureForMobile(source, [UtilsService.getInstance().lng, UtilsService.getInstance().lat]);
      }
    }
  }

  /**
   * Query feature for mobile
   * @param source
   * @param config
   * @param coordinates
   */
     private async queryFeatureForMobile(
      source: any,
      coordinates: any
    ): Promise<void> {
      try {
        const features: Feature[] = await QueryService.queryWMS(this.view, source, coordinates);
        let extent = boundingExtent(this.mapConfig.mobileDefaultExtent);

        if (features && features.length) {
          const featExtent = features[0].getGeometry().getExtent();

          if (featExtent && featExtent.length > 3) {
            extent = [featExtent[1], featExtent[0], featExtent[3], featExtent[2]];
          }
        } else {
          bus.$emit(
            "ArxMapShowMobileNotification"
          );
        }
        
        MappingService.getInstance().view.fit(extent as any);
  
        return Promise.resolve();
      } catch(err) {
        return Promise.reject(err);
      }
    }

  /**
   * Init map layers
   */
  public initMapLayers(): void {
    this.getBasemapLayers()
    .concat(this.getLayers())
    .forEach((layer: TileLayer) => {
      this.map.addLayer(layer);
    })

    this.intStatsLayers();
  }

  /**
   * Zoom in / out
   * @param zoom
   */
  public zoom(zoom: number): void {
    const mapZoom: number = this.view.getZoom() || 0;

    if (
      mapZoom + zoom > ConfigService.getInstance().config.map.maxZoom ||
      mapZoom + zoom < ConfigService.getInstance().config.map.minZoom
    )
      return;

    this.view.animate({
      zoom: mapZoom + zoom,
      duration: 250
    });
  }

  /**
   * Zoom to initial view
   */
  public zoomInitialView(): void {
    this.view.animate({
      zoom: this.mapConfig.zoom,
      center: this.mapConfig.center,
      duration: 250
    });
  }

  /**
   * Set layer visibility
   * @param name
   * @param visible
   */
  public setLayerVisibility(name: string, visible: boolean): void {
    if (this.mapLayers[name] && this.mapLayers[name].layer)
      this.mapLayers[name].layer.setVisible(visible);
  }

  /**
   * Set layer opacity
   * @param name
   * @param opacity
   */
  public setLayerOpacity(name: string, opacity: number): void {
    if (this.mapLayers[name] && this.mapLayers[name].layer)
      this.mapLayers[name].layer.setOpacity(opacity);
  }

  /**
   * Set layer view params
   * @param name
   * @param filterStr
   */
  public setLayerViewparams(name: string, filterStr: string): void {
    if (this.mapLayers[name] && this.mapLayers[name].layer) {
      const layer: TileLayer = this.mapLayers[name].layer;

      if (layer) {
        const source: TileWMS = layer.getSource() as TileWMS;
        const params = source.getParams();
        params.viewparams = filterStr;
        source.updateParams(params);

        //Refresh the map
        source.refresh();
      }
    }
  }

  /**
   * Set layer view params
   * @param name
   * @param params
   */
  public setLayerParams(name: string, params: any[]): void {
    if (this.mapLayers[name]) {
      const layer: TileLayer = this.mapLayers[name].layer;

      if (layer) {
        const source: TileWMS = layer.getSource() as TileWMS;
        const layParams = source.getParams();

        params.forEach((oneParam: any) => {
          layParams[oneParam.param] = oneParam.value;
        });

        source.updateParams(layParams);

        //Refresh the map
        source.refresh();
      }
    }
  }

  /**
   * Get layer by name
   * @param name
   */
  public getLayerByName(name: string): TileLayer {
    return MappingService.getInstance().mapLayers ? MappingService.getInstance().mapLayers[name].layer : null;
  }

  /**
   * Get map layers
   */
  public getMapLayers(): any {
    return this.mapLayers;
  }

  /**
   * Get layer legend url
   * @param name
   */
  public getLayerLegend(name: string): string {
    if (this.mapLayers) {
      const layer: TileLayer = this.mapLayers[name].layer;
      const graphicUrl = (layer.getSource() as TileWMS).getLegendUrl(
        this.view.getResolution()
      );
      return graphicUrl || "";
    }

    return "";
  }

  /**
   * Get layer styles
   * @param name
   */
  public getLayerStyles(
    name: string
  ): Promise<{ styles: string[]; defaultStyle: string }> {
    return new Promise<{ styles: string[]; defaultStyle: string }>(
      (resolve, reject) => {
        fetch(
          `${
            ConfigService.getInstance().config.map.WFSBaseURL
          }?request=GetStyles&layers=${name}&service=wms&version=1.1.1`,
          {
            method: "GET"
          }
        )
          .then(response => response.text())
          .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
          .then(data => {
            const styles = [];

            if (data) {
              let defaultStyle: string;

              Array.from(data.getElementsByTagName("sld:UserStyle")).forEach(
                userStyle => {
                  const arr = Array.from(
                    userStyle.getElementsByTagName("sld:Name")
                  );
                  const styleName: string =
                    arr && arr.length ? arr[0].innerHTML : null;
                  const isDefaultArr = userStyle.getElementsByTagName(
                    "sld:IsDefault"
                  );

                  if (isDefaultArr && isDefaultArr.length) {
                    const isDefault = isDefaultArr[0].innerHTML;
                    if (isDefault && Number(isDefault) === 1) {
                      defaultStyle = styleName;
                    }
                  }

                  if (styleName) {
                    styles.push(styleName);
                  }
                }
              );

              resolve({
                styles: styles
                  .sort()
                  .filter((val, i) => styles.indexOf(val) === i),
                defaultStyle: defaultStyle
              });
            }
          })
          .catch((err: any) => {
            resolve(null);
          });
      }
    );
  }

  /**
   * Handle swipe
   * @param swipeLayer
   */
  public handleSwipe(
    leftLayer: IVectorLayer | any,
    rightLayer: IVectorLayer | any
  ): void {
    // Hide other layers
    for (const name of Object.keys(this.mapLayers)) {
      this.setLayerVisibility(name, false);
    }

    // Show left map
    this.setLayerVisibility(leftLayer.name, true);

    // Add and show right map
    this.removeSwipeLayer();

    const swipeLayer: TileLayer = this.createVectorLayer(rightLayer);
    swipeLayer.setVisible(true);
    this.map.addLayer(swipeLayer);

    this.mapLayers[MappingService.swipeLayerName] = {
      layer: swipeLayer,
      config: rightLayer.layerConfig
    };

    this.initSwipe(swipeLayer);
    this.map.render();
  }

  /**
   * Init swipe
   * @param swipeLayer
   */
  public initSwipe(swipeLayer: TileLayer): void {
    swipeLayer.on("prerender", event => {
      const ctx = event.context;
      const mapSize = this.map.getSize();
      const width = mapSize[0] * (this.swipeValue / 100);
      const tl = getRenderPixel(event, [width, 0]);
      const tr = getRenderPixel(event, [mapSize[0], 0]);
      const bl = getRenderPixel(event, [width, mapSize[1]]);
      const br = getRenderPixel(event, mapSize);

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(tl[0], tl[1]);
      ctx.lineTo(bl[0], bl[1]);
      ctx.lineTo(br[0], br[1]);
      ctx.lineTo(tr[0], tr[1]);
      ctx.closePath();
      ctx.clip();
    });

    swipeLayer.on("postrender", function(event) {
      const ctx = event.context;
      ctx.restore();
    });
  }

  /**
   * Set swipe value
   * @param val
   */
  public setSwipeValue(val: number): void {
    this.swipeValue = val;
    this.map.render();
  }

  /**
   * Cancel swipe
   */
  public cancelSwipe(): void {
    this.removeSwipeLayer();
  }

  /**
   * Get mapping service instance
   */
  public static getInstance(): MappingService {
    if (!MappingService.instance) {
      MappingService.instance = new MappingService();
      MappingService.instance.mapConfig = ConfigService.getInstance().config.map;

      bus.$on("ArxShowPopup", (feature: Feature) => {
        this.getInstance().handlePopupFromSearch(feature);
      });
    }

    return MappingService.instance;
  }

  /**
   * Clear highlights
   */
  public clearHighlights(): void {
    this.highlightLayer.getSource().clear();
  }

  /**
   * Zoom to geometry
   * @param feature
   */
  public zoomToGeom(feature: Feature): void {
    this.view.fit(feature.getGeometry().getExtent(), {
      size: this.map.getSize()
    });
  }

  /**
   * Query feature for popup
   * @param source
   * @param config
   * @param coordinates
   */
  public async queryFeaturesForPopup(
    source: any,
    config: any,
    coordinates: any,
    statsConfig: IStatistics = null,
    isMobileProvinceClick = false
  ): Promise<void> {
    try {
      const features: Feature[] = await QueryService.queryWMS(this.view, source, coordinates);
      let relatedContent = "";
      if (features && features.length) {
        if (config.relatedContent) {
          const filters = [{attribute: config.relatedContent.relationField, value: features[0].getProperties()[config.relatedContent.field]}];
          const relatedFeatures: Feature[] = await QueryService.queryFeatures(QueryService.createFeatureRequest([config.relatedContent.layer], null, filters));
          relatedContent = MappingService.createRelatedContent(relatedFeatures, config.relatedContent);
        }

        this.showPopup(features[0], config, coordinates, relatedContent);
        this.handleRadarStats(features[0], statsConfig);
        this.highlightLayer.getSource().clear();
        this.highlightLayer.getSource().addFeature(features[0]);

        if (isMobileProvinceClick) {
          const provinceId = features[0].getProperties()[ConfigService.getInstance().config.map.provincesLayer.idField];
          bus.$emit("ArxMobileProvinceClickForStats", provinceId);
        }
      }

      return Promise.resolve();
    } catch(err) {
      return Promise.reject(err);
    }
  }
}
