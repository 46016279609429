



import { Component, Vue } from "vue-property-decorator";

@Component
export default class ArxSplitter extends Vue {
  private mounted(): void {
    // Query the element
    const resizer = document.getElementById("dragMe");
    const leftSide = resizer.previousElementSibling;
    const rightSide = resizer.nextElementSibling;

    // The current position of mouse
    let x = 0;
    let y = 0;
    let leftWidth = 0;

    const mouseMoveHandler = function(e) {
      // How far the mouse has been moved
      const dx = e.clientX - x;
      const dy = e.clientY - y;

      const newLeftWidth =
        ((leftWidth + dx) * 100) /
        (resizer.parentNode as any).getBoundingClientRect().width;
      (leftSide as any).style.width = `${newLeftWidth}%`;

      resizer.style.cursor = "col-resize";
      document.body.style.cursor = "col-resize";

      (leftSide as any).style.userSelect = "none";
      (leftSide as any).style.pointerEvents = "none";

      (rightSide as any).style.userSelect = "none";
      (rightSide as any).style.pointerEvents = "none";
    };

    const mouseUpHandler = function() {
      resizer.style.removeProperty("cursor");
      document.body.style.removeProperty("cursor");

      (leftSide as any).style.removeProperty("user-select");
      (leftSide as any).style.removeProperty("pointer-events");

      (rightSide as any).style.removeProperty("user-select");
      (rightSide as any).style.removeProperty("pointer-events");

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function(e) {
      // Get the current mouse position
      x = e.clientX;
      y = e.clientY;
      leftWidth = leftSide.getBoundingClientRect().width;

      // Attach the listeners to `document`
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    // Attach the handler
    resizer.addEventListener("mousedown", mouseDownHandler);
  }
}
