



import { Component, Vue } from "vue-property-decorator";

@Component
export default class Credits extends Vue {
  /**
   * Get image
   */
  private getImage(image: string): any {
    return require(`@/assets/credits/${image}`);
  }
}
