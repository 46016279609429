export class UtilsService {

  private static instance: UtilsService;
  private mobile = 0;
  public lat = 0;
  public lng = 0;

  /**
   * Set url parameters
   * @returns
   */
   private setUrlParameters(): void {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    
    const latParam: string = searchParams.get('lat');
    const lngParam: string = searchParams.get('lng');
    const mobileParam: string = searchParams.get('mobile');

    this.lat = latParam && !isNaN(Number(latParam)) ? Number(latParam) : 0;
    this.lng = lngParam && !isNaN(Number(lngParam)) ? Number(lngParam) : 0; 
    this.mobile = mobileParam && !isNaN(Number(mobileParam)) ? Number(mobileParam) : 0;
  }

  /**
   * Check is a mobile screen
   * @returns
   */
  public isMobileScreen(): boolean {
    return this.mobile != 0;
  }

  /**
   * Get config service instance
   */
   public static getInstance(): UtilsService {
    if (!UtilsService.instance) {
      UtilsService.instance = new UtilsService();
      UtilsService.instance.setUrlParameters();
    }

    return UtilsService.instance;
  }
}
