import Overlay from "ol/Overlay";
import { MappingService } from "./mappingService";
import ArxMap from "@/components/ArxMap/ArxMap.vue";

export interface PopupElements {
  container: HTMLElement;
  title: HTMLElement;
  content: HTMLElement;
  prevNext: HTMLElement;
  closer: HTMLElement;
  overlay: Overlay;
}

export class PopupService {
  private static instance: PopupService;
  private overlay: Overlay;
  public popupElements: PopupElements;
  public currentPopupFeatures: any[] = [];
  public currentPopupIndex = 0;
  private arxMap: ArxMap;

  /**
   * Get popup service instance
   */
  public static getInstance(): PopupService {
    if (!PopupService.instance) {
      PopupService.instance = new PopupService();
    }

    return PopupService.instance;
  }

  
  /**
   * Init locale
   */
  public initLocales(arxMap: ArxMap): void {
    if(PopupService.instance) {
      PopupService.instance.arxMap = arxMap;
    }
  }


  /**
   * Init popup
   */
  public initPopup(): void {
    //Elements that make up the popup.
    this.popupElements = {
      container: document.getElementById("popup"),
      title: document.getElementById("popup-title"),
      content: document.getElementById("popup-content"),
      prevNext: document.getElementById("popup-prev-next"),
      closer: document.getElementById("popup-closer"),
      overlay: null
    };

    // Create an overlay to anchor the popup to the map.
    this.overlay = new Overlay({
      element: this.popupElements.container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    // Add a click handler to hide the popup.
    this.popupElements.closer.onclick = () => {
      this.closePopup();
      return false;
    };

    this.popupElements.overlay = this.overlay;
  }

  /**
   * Close popup
   */
  public closePopup(): void {
    this.overlay.setPosition(undefined);
    this.popupElements.closer.blur();
    MappingService.getInstance().clearHighlights();
  }

  /**
   * Open popup
   * @param attributes
   * @param config
   * @param coordinates
   * @param relatedContent
   */
  public openPopup(attributes: any, config: any, coordinates: any, relatedContent = "") {
    let title = this.arxMap.$t(config.title);
    let content = "";

    for (const key in attributes) {
      if (title.toString().indexOf(`${key}`) > -1) {
        const titleValue = attributes[key] ? attributes[key] : "-";
        title = title.toString().replace("${" + key + "}", titleValue);
      }
    }

    config.content.forEach((field: any) => {
      const value = attributes[field.propertyName]
        ? attributes[field.propertyName]
        : "-";
      content += `<b>${this.arxMap.$t(field.label)}</b>: ${value}</br>`;
    });

    content += `</br>`;
    content += `<div id="popupPrevNextDiv" class="popupPrevNextDiv"></div>`;

    this.popupElements.title.innerHTML = title.toString()
      .split("$")
      .join("")
      .split("{")
      .join("")
      .split("}")
      .join("");

    if(relatedContent) {
      content += `<b>${this.arxMap.$t('translate.relatedItems')}</b>`;
      content += `</br>`;
      content += `</br>`;
      content += relatedContent;
    }

    this.popupElements.content.innerHTML = content;
    this.popupElements.overlay.setPosition(coordinates);

    if (this.currentPopupFeatures.length > 1) {
      this.popupElements.prevNext.classList.remove("hidden");
    } else {
      this.popupElements.prevNext.classList.add("hidden");
    }
  }

  /**
   *
   */
  public reinitializeState(): void {
    this.currentPopupFeatures = [];
    this.currentPopupIndex = 0;
  }
}
