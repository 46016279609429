




















import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/Header/Header.vue";
import ArxSideBar from "@/components/ArxSideBar/ArxSideBar.vue";
import { ConfigService } from "@/services/configService";
import { Route } from "vue-router";
import { bus } from "./main";

@Component({
  components: {
    Header,
    ArxSideBar
  }
})
export default class App extends Vue {
  private configLoaded = false;
  private showError = false;
  private sidebarkey = 0;
  private mobileNotificationVisible = false;
  private mobileLoadingVisible = false;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newRoute: Route) {
    if (newRoute.name === "MapWithParam") {
      this.sidebarkey = new Date().getTime();
      //this.$refs.arxMap.reinitializeMapLayers();
    }
  }
  
  private mounted(): void {
    const urlThematicParam = this.$route.query.thematic as string;
    ConfigService.getInstance()
      .loadConfig(urlThematicParam)
      .then(() => {
        this.configLoaded = true;
        //if(!urlThematicParam) {
        //this.$router.push({ name: 'Home'});
        //}
      })
      .catch((err: any) => {
        this.showError = true;
        console.error("Unable to load configuration from config.json !");
      });

      bus.$on("ArxMapShowMobileNotification", () => {
        this.mobileNotificationVisible = true;
        
        setTimeout(() => {
          this.mobileNotificationVisible = false;
        }, 3000);
      });

      bus.$on("ArxMapHideMobileLoading", () => {
        this.mobileLoadingVisible = false;
      });
  }
}
